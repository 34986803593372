import React, { Suspense, useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { FaroRoute } from '@grafana/faro-react';

import Loadable from "react-loadable";
import { connect } from "react-redux";

import "../../node_modules/font-awesome/scss/font-awesome.scss";
import "react-toastify/dist/ReactToastify.css";
import "swiper/swiper-bundle.css";

import { toast } from "react-toastify";
import Loader from "./layout/Loader";
import ScrollToTop from "./layout/ScrollToTop";
import * as actions from "../store/actions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Terms from "./components/Terms";

const AdminLayout = Loadable({
  loader: () => import("./layout/AdminLayout"),
  loading: Loader,
});

// const CustomerLayout = Loadable({
//   loader: () => import("./layout/Customer"),
//   loading: Loader,
// });

const AuthLayout = Loadable({
  loader: () => import("./layout/Auth"),
  loading: Loader,
});

// const AuthV2Layout = Loadable({
//   loader: () => import("./layout/AuthV2"),
//   loading: Loader,
// });

const DocsLayout = Loadable({
  loader: () => import("./layout/Docs"),
  loading: Loader,
});
const QueueLayout = Loadable({
  loader: () => import("./layout/Queue"),
  loading: Loader,
});
const SpotifyLayout = Loadable({
  loader: () => import("./layout/Spotify"),
  loading: Loader,
});
const App = (props) => {
  //const location = useLocation();

  useEffect(() => {
    props.autoSignIn();

    toast.configure({
      style: { fontWeight: "bold" },
      className: "text-center",
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScrollToTop>
      <Suspense fallback={<Loader />}>
        <Switch>
          <FaroRoute path="/docs" component={DocsLayout} />
          <FaroRoute path="/terms" component={Terms} />
          <FaroRoute path="/privacy/policy" component={PrivacyPolicy} />
          <FaroRoute path="/spotify" component={SpotifyLayout} />
          <FaroRoute path="/queue" component={QueueLayout} />
          {/* <FaroRoute
            path="/v2"
            component={props.isLoggedIn ? CustomerLayout : AuthV2Layout}
          /> */}
          <FaroRoute
            path="/"
            component={props.isLoggedIn ? AdminLayout : AuthLayout}
          />
        </Switch>
      </Suspense>
    </ScrollToTop>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    autoSignIn: () => dispatch(actions.authCheckState()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
