import {
  NETWORK_LOADING,
  NETWORK_ERROR,
  ADD_AP,
  ADD_SSID,
  ADD_APGROUP,
  GET_APS,
  GET_SSIDS,
  GET_MODELS,
  SET_APSCOMPONENT,
  SET_SSIDSCOMPONENT,
  SET_GROUPSCOMPONENT,
  GET_APGROUPS,
  GET_SECRET,
  TOGGLE_MAP,
  CHANGE_APFORM,
  CHANGE_SSIDFORM,
  CHANGE_APGROUPFORM,
  SET_COORDINATES,
  GET_SCREENS,
  ADD_SCREEN,
  SET_SCREENSCOMPONENT,
  CHANGE_SCREENSFORM,
} from "../types";

const initialState = {
  isLoading: false,
  error: null,
  aps: [],
  apgroups: [],
  ssids: [],
  screens: [],
  models: [],
  APformValues: { model: "choose", protocol: "HTTP" },
  ssidForm: { Name: "", QR: "" },
  apgroupForm: { APs: "" },
  coordinates: {},
  showMap: false,
  secret: "",
  apsComponent: "overview",
  ssidsComponent: "overview",
  screensComponent: "overview",
  screensForm: { type: "3" },
  groupsComponent: "overview",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case NETWORK_LOADING:
      return {
        ...state,
        isLoading: action.payload,
        error: action.payload && null,
      };
    case NETWORK_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case ADD_AP:
      return {
        ...state,
        aps: [...state.aps, action.payload],
      };
    case ADD_SSID:
      return {
        ...state,
        ssids: [...state.ssids, action.payload],
      };
    case ADD_APGROUP:
      return {
        ...state,
        apgroups: [...state.apgroups, action.payload],
      };
    case CHANGE_APFORM:
      return {
        ...state,
        APformValues: action.payload,
      };
    case CHANGE_SSIDFORM:
      return {
        ...state,
        ssidForm: action.payload,
      };
    case CHANGE_APGROUPFORM:
      return {
        ...state,
        apgroupForm: action.payload,
      };
    case TOGGLE_MAP:
      return {
        ...state,
        showMap: action.payload,
      };
    case SET_COORDINATES:
      return {
        ...state,
        APformValues: {
          ...state.APformValues,
          lng: action.lng,
          lat: action.lat,
        },
      };
    // case ADD_SSID:
    //   return {
    //     ...state,
    //     aps: [...state.ssids, action.payload],
    //   };
    // case ADD_APGROUP:
    //   return {
    //     ...state,
    //     aps: [...state.apgroups, action.payload],
    //   };
    case GET_SSIDS:
      return {
        ...state,
        ssids: action.payload,
      };
    case GET_MODELS:
      return {
        ...state,
        models: action.payload,
      };
    case GET_SECRET:
      return {
        ...state,
        secret: action.payload,
        APformValues: { ...state.APformValues, secret: action.payload },
      };
    case GET_APGROUPS:
      return {
        ...state,
        apgroups: action.payload,
      };
    case GET_APS:
      return {
        ...state,
        aps: action.payload,
      };
    case SET_APSCOMPONENT:
      return {
        ...state,
        apsComponent: action.payload,
      };
    case SET_SSIDSCOMPONENT:
      return {
        ...state,
        ssidsComponent: action.payload,
      };
    case SET_GROUPSCOMPONENT:
      return {
        ...state,
        groupsComponent: action.payload,
      };

    // Screens Actions Types
    case ADD_SCREEN:
      return {
        ...state,
        screens: [...state.screens, action.payload],
      };
    case GET_SCREENS:
      return {
        ...state,
        screens: action.payload,
      };
    case SET_SCREENSCOMPONENT:
      return {
        ...state,
        screensComponent: action.payload,
      };
    case CHANGE_SCREENSFORM:
      return {
        ...state,
        screensForm: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
