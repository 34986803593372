import axios from "axios";

import { DOCS_SET_MODULES } from "../types";

export const setDocsModules = (data) => ({
  type: DOCS_SET_MODULES,
  payload: data,
});

// get locations and access points to connect them together
export const getDocsModules = (cp_id) => {
  return async (dispatch, getState) => {
    console.log("Hello from reducer");
    try {
      const docs = await axios.get(
        `https://hmcp.dev.hoopoe.digital/api/docs/get`
      );
      let data = await docs.data.data;
      console.log({ data });
      dispatch(setDocsModules(data));
    } catch (e) {
      console.log(e);
    }
  };
};
