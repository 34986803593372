import axios from "axios";
import {
  CLOUD_LOADING,
  CLOUD_ERROR,
  ADD_CP,
  GET_OFFERS,
  GET_SSIDS,
  GET_APS,
  GET_APGROUPS,
  SET_COMPONENT,
  SET_GATEWAY_COMPONENT,
  CHANGE_GATEWAY_FORM,
  START_GATEWAY_REQ,
  END_GATEWAY_REQ,
  SET_GATEWAY_DATA,
  SET_GATEWAY_FORM,
  SET_EXTRA_PACKAGES,
} from "../types";
import { toast } from "react-toastify";
import { setSelectedGateway } from "./cpEditorActions";
// Actions
export const setCloudLoading = (val) => {
  return { type: CLOUD_LOADING, payload: val };
};
export const cloudError = (err) => {
  return { type: CLOUD_ERROR, error: err };
};
export const addCP = (cps) => {
  return { type: ADD_CP, payload: cps };
};
export const setOffers = (offers) => {
  return { type: GET_OFFERS, payload: offers };
};
export const setExtraPackages = (offers) => {
  return { type: SET_EXTRA_PACKAGES, payload: offers };
};
export const setSSIDs = (ssids) => {
  return { type: GET_SSIDS, payload: ssids };
};
export const setAPS = (aps) => {
  return { type: GET_APS, payload: aps };
};
export const setAPGroups = (apgroups) => {
  return { type: GET_APGROUPS, payload: apgroups };
};
export const setCurrentComponent = (current) => {
  return { type: SET_COMPONENT, payload: current };
};
export const setGatewayComponent = (id) => {
  return { type: SET_GATEWAY_COMPONENT, payload: id };
};
export const setGateways = (data) => {
  return { type: SET_GATEWAY_DATA, payload: data };
};
export const changeGatewayForm = (property, value) => {
  return { type: CHANGE_GATEWAY_FORM, property, value };
};
export const setGatewayForm = (form) => {
  return { type: SET_GATEWAY_FORM, payload: form };
};
export const startGatewayReq = () => {
  return { type: START_GATEWAY_REQ };
};
export const endGatewayReq = (error) => {
  return { type: END_GATEWAY_REQ, error };
};
export const getGateways = () => {
  return (dispatch, getState) => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/cloud/smsgateways/all`,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => {
        // console.log(res.data.data);
        if (res.data.data.length) {
          dispatch(setGateways(res.data.data));
          dispatch(setSelectedGateway(res.data.data[0].ID));
        } else {
          //Handle this
          console.log("No gateways were found");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const addGateway = () => {
  return (dispatch, getState) => {
    dispatch(startGatewayReq());
    axios({
      method: "post",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/cloud/smsgateways/add`,
      data: getState().cloud.gatewayForm,
    })
      .then((result) => {
        console.log(result.data.error);
        if (result.data.status === "failed") {
          dispatch(endGatewayReq(result.data.error));
        } else {
          dispatch(endGatewayReq(null));
          dispatch(setGatewayComponent("overview"));
          toast.success("SMS Gateway added successfully");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
};
export const editGateway = () => {
  return (dispatch, getState) => {
    let form = getState().cloud.gatewayForm;
    dispatch(startGatewayReq());
    axios({
      method: "post",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/cloud/smsgateways/edit`,
      data: { gateway: form },
    })
      .then((result) => {
        console.log(result.data.error);
        if (result.data.status === "failed") {
          dispatch(endGatewayReq(result.data.error));
        } else {
          dispatch(endGatewayReq(null));
          dispatch(setGatewayComponent("overview"));
          toast.success("SMS Gateway edited successfully");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
};
export const getCaptivePortals = () => {
  return (dispatch) => {
    dispatch(setCloudLoading(true));
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/cloud/captiveportals/all`,
    })
      .then((result) => {
        // console.log(result.data.data);
        if ((result.data.message = "Fetched"))
          dispatch(addCP(result.data.data));
        localStorage.setItem(
          "captivePortals",
          JSON.stringify(result.data.data)
        );
      })
      .catch((err) => {
        dispatch(cloudError(err));
      })
      .finally(() => {
        dispatch(setCloudLoading(false));
      });
  };
};

export const getOffers = () => {
  return (dispatch) => {
    dispatch(setCloudLoading(true));
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/offers/all`,
    })
      .then((result) => {
        // console.log(result.data);
        dispatch(setOffers(result.data));
      })
      .catch((err) => {
        dispatch(cloudError(err));
      })
      .finally(() => {
        dispatch(setCloudLoading(false));
      });
  };
};
export const getExtraPackages = () => {
  return (dispatch) => {
    dispatch(setCloudLoading(true));
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/offers/allextra`,
    })
      .then((result) => {
        console.log(result.data);
        dispatch(setExtraPackages(result.data));
      })
      .catch((err) => {
        dispatch(cloudError(err));
      })
      .finally(() => {
        dispatch(setCloudLoading(false));
      });
  };
};

export const getSSIDs = () => {
  return (dispatch) => {
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/network/ssids/all`,
    })
      .then((result) => {
        console.log(result.data);
        dispatch(setSSIDs(result.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getAPGroups = () => {
  return (dispatch) => {
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/network/groups/all`,
    })
      .then((result) => {
        //console.log(result.data);
        dispatch(setAPGroups(result.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getAPs = () => {
  return (dispatch) => {
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/network/accesscontrollers/all`,
    })
      .then((result) => {
        //console.log(result.data);
        dispatch(setAPS(result.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
