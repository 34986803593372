import {
  SET_USER_INFO,
  TOGGLE_SIDENAV,
  SET_CATEGORIES,
  SET_LOCATIONS,
  SELECT_CATEGORY,
  SELECT_ITEM,
  ADD_TO_CART,
  DELETE_FROM_CART,
  EMPTY_CART,
  SET_POSROCKET_TOKEN,
  SET_APP_COMPONENT,
  SET_POS_ITEMS,
  PR_RESET_STATE,
  PR_FILTER_CATEGORIES,
} from "../types";

const initialState = {
  userInfo: {},
  currentComponent: "items",
  access_token: null,
  error: null,
  isSidenav: false,
  categories: [],
  filtered_categories: [],
  items: [],
  locations: [],
  accessPoints: [],
  selected_category: null,
  selected_item: null,
  orders: [],
  cartItems: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case SET_POSROCKET_TOKEN:
      return {
        ...state,
        access_token: action.payload,
      };
    case TOGGLE_SIDENAV:
      return {
        ...state,
        isSidenav: !state.isSidenav,
      };
    case SET_POS_ITEMS:
      return {
        ...state,
        items: action.payload,
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case PR_FILTER_CATEGORIES:
      return {
        ...state,
        filtered_categories: action.cat_arr
          ? // filter using the array
            state.categories.map((c) => ({
              id: c.id,
              name: c.name,
              checked: action.cat_arr.includes(c.name) ? true : false,
            }))
          : // change object value
            state.filtered_categories.map((c) =>
              c.name === action.cat_name
                ? { ...c, checked: !c.checked }
                : { ...c }
            ),
      };
    case SET_LOCATIONS:
      return {
        ...state,
        locations: action.locations,
        accessPoints: action.accessPoints,
      };
    case SELECT_CATEGORY:
      return {
        ...state,
        selected_category: action.payload,
      };
    case SELECT_ITEM:
      return {
        ...state,
        selected_item: action.payload,
      };
    case ADD_TO_CART:
      return {
        ...state,
        orders: [action.payload.item, ...state.orders],
        cartItems: [...state.cartItems, action.payload.cart],
      };
    case DELETE_FROM_CART:
      return {
        ...state,
        cartItems: state.cartItems.filter((item) => item.id !== action.payload),
      };
    case EMPTY_CART:
      return {
        ...state,
        cartItems: [],
        orders: [],
      };
    case SET_APP_COMPONENT:
      return {
        ...state,
        currentComponent: action.payload,
      };
    case PR_RESET_STATE:
      return {
        ...state,
        locations: [],
        accessPoints: [],
      };
    default:
      return state;
  }
};

export default reducer;
