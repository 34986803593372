import axios from "axios";
import { toast } from "react-toastify";
import {
  NETWORK_LOADING,
  NETWORK_ERROR,
  ADD_AP,
  ADD_SSID,
  ADD_APGROUP,
  GET_MODELS,
  GET_SECRET,
  GET_SSIDS,
  GET_APS,
  GET_APGROUPS,
  SET_APSCOMPONENT,
  SET_SSIDSCOMPONENT,
  SET_GROUPSCOMPONENT,
  TOGGLE_MAP,
  CHANGE_APFORM,
  CHANGE_SSIDFORM,
  SET_COORDINATES,
  CHANGE_APGROUPFORM,
  GET_SCREENS,
  ADD_SCREEN,
  SET_SCREENSCOMPONENT,
  CHANGE_SCREENSFORM,
} from "../types";

// Actions
export const setNetworkLoading = (val) => {
  return { type: NETWORK_LOADING, payload: val };
};
export const networkError = (err) => {
  return { type: NETWORK_ERROR, error: err };
};
export const addAP = (ap) => {
  return { type: ADD_AP, payload: ap };
};

export const changeFormValues = (formValues) => {
  return { type: CHANGE_APFORM, payload: formValues };
};
export const changeSSIDForm = (ssidForm) => {
  return { type: CHANGE_SSIDFORM, payload: ssidForm };
};
export const changeAPGroupForm = (groupForm) => {
  return { type: CHANGE_APGROUPFORM, payload: groupForm };
};
export const toggleMap = (val) => {
  return { type: TOGGLE_MAP, payload: val };
};
export const setCoordinates = (payload) => {
  return { type: SET_COORDINATES, lng: payload.lng, lat: payload.lat };
};
export const addSSID = (ssid) => {
  return { type: ADD_SSID, payload: ssid };
};
export const addAPGroup = (apgroup) => {
  return { type: ADD_APGROUP, payload: apgroup };
};
export const setSSIDs = (ssids) => {
  return { type: GET_SSIDS, payload: ssids };
};
export const setAPs = (aps) => {
  return { type: GET_APS, payload: aps };
};
export const setModels = (models) => {
  return { type: GET_MODELS, payload: models };
};
export const setSecret = (secret) => {
  return { type: GET_SECRET, payload: secret };
};
export const setAPGroups = (apgroups) => {
  return { type: GET_APGROUPS, payload: apgroups };
};
export const setAPsComponent = (current) => {
  return { type: SET_APSCOMPONENT, payload: current };
};
export const setSSIDsComponent = (current) => {
  return { type: SET_SSIDSCOMPONENT, payload: current };
};
export const setGroupsComponent = (current) => {
  return { type: SET_GROUPSCOMPONENT, payload: current };
};

export const getModels = () => {
  return (dispatch) => {
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_HDS_API_URL}/router-types`,
    })
      .then((result) => {
        if (result.data.routerTypes)
          dispatch(setModels(result.data.routerTypes));
      })
      .catch((err) => {
        dispatch(networkError(err));
      });
  };
};

export const getSSIDs = () => {
  return (dispatch) => {
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/network/ssids/all`,
    })
      .then((result) => {
        // console.log(result.data);
        dispatch(setSSIDs(result.data));
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const getSecret = () => {
  return (dispatch) => {
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/network/secret`,
    })
      .then((result) => {
        dispatch(setSecret(result.data.secret));
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const getAPGroups = () => {
  return (dispatch) => {
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/network/groups/all`,
    })
      .then((result) => {
        // console.log(result.data);
        dispatch(setAPGroups(result.data.data));
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const getAPs = () => {
  return (dispatch) => {
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/network/accesscontrollers/all`,
    })
      .then((result) => {
        // console.log(result.data.data);
        dispatch(setAPs(result.data.data));
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const addNewAP = (ap) => {
  return (dispatch) => {
    dispatch(setNetworkLoading(true));
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${process.env.REACT_APP_API_URL}/network/accesscontrollers/add`,
      data: ap,
    })
      .then((result) => {
        dispatch(setNetworkLoading(false));
        if (result.data.message === "Added") {
          dispatch(addAP(result.data.data));
          toast.success("✔ Added Successfully");
          dispatch(setAPsComponent("overview"));
          dispatch(changeFormValues({ model: "choose", protocol: "HTTP" }));
        } else {
          dispatch(networkError(result.data.message));
        }
      })
      .catch((err) => console.error(err));
  };
};

export const updateAP = (ap) => {
  return (dispatch, getState) => {
    dispatch(setNetworkLoading(true));
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${process.env.REACT_APP_API_URL}/network/accesscontrollers/edit`,
      data: ap,
    })
      .then((result) => {
        dispatch(setNetworkLoading(false));
        if (result.data.message === "Updated") {
          let data = getState().network.aps;
          let index = data.findIndex((ac) => ac.id === ap.id);
          data.splice(index, 1);
          data.splice(index, 0, ap);
          dispatch(setAPs(data));
          toast.success("✔ Updated Successfully");
          dispatch(setAPsComponent("overview"));
          dispatch(changeFormValues({ model: "choose", protocol: "HTTP" }));
        } else {
          dispatch(networkError(result.data.message));
        }
      })
      .catch((err) => console.error(err));
  };
};

export const addNewSSID = (ssid) => {
  return (dispatch) => {
    dispatch(setNetworkLoading(true));
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${process.env.REACT_APP_API_URL}/network/ssids/add`,
      data: ssid,
    })
      .then((result) => {
        dispatch(setNetworkLoading(false));
        if (result.data.message === "Added") {
          dispatch(addSSID(result.data.data));
          toast.success("✔ Added Successfully");
          dispatch(setSSIDsComponent("overview"));
          dispatch(changeSSIDForm({}));
        } else {
          dispatch(networkError(result.data.message));
        }
      })
      .catch((err) => console.error(err));
  };
};

export const updateSSID = (ssid) => {
  return (dispatch, getState) => {
    dispatch(setNetworkLoading(true));
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${process.env.REACT_APP_API_URL}/network/ssids/edit`,
      data: ssid,
    })
      .then((result) => {
        dispatch(setNetworkLoading(false));
        if (result.data.message === "Updated") {
          let data = getState().network.ssids;
          let index = data.findIndex((ss) => ss.ID === ssid.ID);
          data.splice(index, 1);
          data.splice(index, 0, ssid);
          dispatch(setSSIDs(data));
          toast.success("✔ Updated Successfully");
          dispatch(setSSIDsComponent("overview"));
          dispatch(changeSSIDForm({}));
        } else {
          dispatch(networkError(result.data.message));
        }
      })
      .catch((err) => console.error(err));
  };
};

export const addNewGroup = (apgroup) => {
  return (dispatch) => {
    dispatch(setNetworkLoading(true));
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${process.env.REACT_APP_API_URL}/network/groups/add`,
      data: apgroup,
    })
      .then((result) => {
        dispatch(setNetworkLoading(false));
        if (result.data.message === "Added") {
          dispatch(addAPGroup(result.data.data));
          toast.success("✔ Added Successfully");
          dispatch(setGroupsComponent("overview"));
          dispatch(changeAPGroupForm({ APs: "" }));
        } else {
          dispatch(networkError(result.data.message));
        }
      })
      .catch((err) => console.error(err));
  };
};

export const updateGroup = (apgroup) => {
  return (dispatch, getState) => {
    dispatch(setNetworkLoading(true));
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${process.env.REACT_APP_API_URL}/network/groups/edit`,
      data: apgroup,
    })
      .then((result) => {
        dispatch(setNetworkLoading(false));
        if (result.data.message === "Updated") {
          let data = getState().network.apgroups;
          let index = data.findIndex((ss) => ss.ID === apgroup.ID);
          data.splice(index, 1);
          apgroup.APCount = apgroup.APs.split(",").length;
          data.splice(index, 0, apgroup);
          dispatch(setAPGroups(data));
          toast.success("✔ Updated Successfully");
          dispatch(setGroupsComponent("overview"));
          dispatch(changeAPGroupForm({ APs: "" }));
        } else {
          dispatch(networkError(result.data.message));
        }
      })
      .catch((err) => console.error(err));
  };
};

// Screens Action

export const addScreen = (ssid) => {
  return { type: ADD_SCREEN, payload: ssid };
};

export const setScreens = (screens) => {
  return { type: GET_SCREENS, payload: screens };
};

export const setScreensComponent = (current) => {
  return { type: SET_SCREENSCOMPONENT, payload: current };
};

export const changeScreensForm = (screenForm) => {
  return { type: CHANGE_SCREENSFORM, payload: screenForm };
};

export const getScreens = () => {
  return (dispatch) => {
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/network/screens`,
    })
      .then((result) => {
        // console.log(result.data);
        dispatch(setScreens(result.data));
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const addNewScreen = (screen) => {
  return (dispatch) => {
    dispatch(setNetworkLoading(true));
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${process.env.REACT_APP_API_URL}/network/screens`,
      data: screen,
    })
      .then((result) => {
        dispatch(setNetworkLoading(false));
        if (result.data.message === "Added") {
          dispatch(addScreen(result.data.data));
          toast.success("✔ Added Successfully");
          dispatch(setScreensComponent("overview"));
          dispatch(changeScreensForm({}));
        } else {
          dispatch(networkError(result.data.message));
        }
      })
      .catch((err) => console.error(err));
  };
};

export const updateScreen = (screen) => {
  return (dispatch, getState) => {
    dispatch(setNetworkLoading(true));
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${process.env.REACT_APP_API_URL}/network/screens`,
      data: screen,
    })
      .then((result) => {
        dispatch(setNetworkLoading(false));
        if (result.data.message === "Updated") {
          let data = getState().network.screens;
          let index = data.findIndex((ss) => ss.id === screen.id);
          data.splice(index, 1);
          data.splice(index, 0, screen);
          dispatch(setScreens(data));
          toast.success("✔ Updated Successfully");
          dispatch(setScreensComponent("overview"));
          dispatch(changeScreensForm({}));
        } else {
          dispatch(networkError(result.data.message));
        }
      })
      .catch((err) => console.error(err));
  };
};
