import axios from "axios";
import { SET_VOUCHERS } from "../types";

// Actions

export const getVouchers = () => {
  return (dispatch) => {
    console.log("getting vouchers ...");
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/vouchers/all`,
    })
      .then((result) => {
        return dispatch({ type: SET_VOUCHERS, payload: result.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
