import {
  SET_QS_QUEUE,
  SET_DB_BRANCHES,
  SET_DB_BRANCH,
  SET_DB_UPDATETIME,
  //TOGGLE_NEW_ORDER,
  SET_MODAL_ITEM,
  SET_ORDERS_DATA,
  SET_RESTAURANT_NAME,
  SET_NEW_ORDERS,
  MAKE_ORDERS_SEEN,
  SET_LOGS_CP,
  SAVE_LOGS,
  CHANGE_LOGS_FILTER,
  SET_DB_COUNTER,
  RESET_QS_STATE,
  CHANGE_WIFI_AUTH_COMP,
  TRIGGER_NOTIFICATION,
  DOARAK_SET_APP,
} from "../types";

const initialState = {
  isLoading: false,
  error: null,
  cpid: "",
  app: "",
  queue: [],
  branches: [],
  counters: [],
  services: [],
  customerTypes: [],
  selectedGateway: "",
  counterHandler: "Counter",
  defaultAudioLanguage: "En",
  tellersView: "",
  counter: "",
  branch: "",
  updatedAt: "",
  newOrder: false,
  modalItem: "",
  restaurantName: "",
  orders: [],
  newOrders: [],
  logsCP: "",
  logs: [],
  logsFilter: { page: "", mac: "", cpid: "" },
  wifiAuthComp: "",
  triggerNotification: {
    type: "",
    toggle: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_QS_QUEUE:
      return {
        ...state,
        queue: action.payload,
      };
    case DOARAK_SET_APP:
      return {
        ...state,
        app: action.payload,
      };
    case SET_DB_BRANCH:
      let branch = state.branches.filter((b) => b.id === action.payload)[0];
      var cs = [];
      for (let i = 1; i <= branch.counters; i++) {
        console.log(i);
        cs.push(i);
      }
      console.log(cs);
      return {
        ...state,
        branch: action.payload,
        tellersView: branch.tellersView,
        counters: cs,
      };
    case SET_DB_UPDATETIME:
      let d = new Date();
      let n = d.toLocaleTimeString();
      return {
        ...state,
        updatedAt: n,
      };
    case SET_DB_BRANCHES:
      return {
        ...state,
        branches: action.branches,
        branch: "",
        selectedGateway: action.selectedGateway,
        cpid: action.cpid,
        app: action.app,
        counterHandler: action.appDetails.counterHandler
          ? action.appDetails.counterHandler
          : "Counter",
        defaultAudioLanguage: action.appDetails.defaultAudioLanguage
          ? action.appDetails.defaultAudioLanguage
          : "En",
        services: action.appDetails.services,
        customerTypes: action.appDetails.customerTypes,
        ticketsQueue: action.appDetails.ticketsQueue
          ? action.appDetails.ticketsQueue
          : "alphabet",
      };
    case SET_MODAL_ITEM:
      return {
        ...state,
        modalItem: action.payload,
      };
    case SET_ORDERS_DATA:
      return {
        ...state,
        orders: action.payload,
      };
    case SET_RESTAURANT_NAME:
      return {
        ...state,
        restaurantName: action.payload,
      };
    case SET_NEW_ORDERS:
      return {
        ...state,
        newOrder: !state.newOrder,
        newOrders: [action.payload, ...state.newOrders],
      };
    case MAKE_ORDERS_SEEN:
      return {
        ...state,
        newOrders: state.newOrders.map((o) => ({ ...o, seen: true })),
      };
    case SET_LOGS_CP:
      return {
        ...state,
        logsCP: action.payload,
      };
    case SAVE_LOGS:
      return {
        ...state,
        logs: action.payload,
      };
    case SET_DB_COUNTER:
      return {
        ...state,
        counter: action.payload,
      };
    case CHANGE_LOGS_FILTER:
      return {
        ...state,
        logsFilter: {
          ...state.logsFilter,
          [action.prop]: action.value,
        },
      };
    case RESET_QS_STATE:
      let newState = JSON.parse(action.state);
      console.log(newState);
      return JSON.parse(action.state);
    case CHANGE_WIFI_AUTH_COMP:
      return { ...state, wifiAuthComp: action.payload };
    case TRIGGER_NOTIFICATION:
      return {
        ...state,
        triggerNotification: {
          type: action.payload,
          toggle: !state.triggerNotification.toggle,
        },
      };
    default:
      return state;
  }
};

export default reducer;
