import React from "react";
import { Link } from "react-router-dom";

export default function Terms() {
  return (
    <>
      <nav style={{ padding: "20px" }}>
        <Link to="/">
          <a className="navbar-brand" href="#!">
            <img
              src="../../assets/images/basic.png"
              width="60"
              height="60"
              alt=""
            />
          </a>
        </Link>
      </nav>
      <div
        style={{ padding: "20px", fontSize: "20px" }}
        dangerouslySetInnerHTML={{
          __html: `
          <p align="center" style="margin-bottom: 0in; line-height: 100%">
          <u><b>Terms of Use - Public WiFi Service</b></u></p>
          <p style="margin-bottom: 0in; line-height: 100%"><br/>
          
          </p>
          <p style="margin-bottom: 0.09in; line-height: 0.2in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt"><b>Terms
          and Conditions</b></font></font></font></p>
          <p style="margin-bottom: 0.09in; line-height: 0.2in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">By
          using our internet service, you hereby expressly acknowledge and
          agree that there are significant security, privacy and
          confidentiality risks inherent in accessing or transmitting
          information through the internet, whether the connection is
          facilitated through wired or wireless technology. Security issues
          include, without limitation, interception of transmissions, loss of
          data, and the introduction or viruses and other programs that can
          corrupt or damage your computer.</font></font></font></p>
          <p style="margin-bottom: 0.09in; line-height: 0.2in"><a name="_GoBack"></a>
          <font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">Accordingly,
          you agree that the owner and/or provider of this network is NOT
          liable for any interception or transmissions, computer worms or
          viruses, loss of data, file corruption, hacking or damage to your
          computer or other devices that result from the transmission or
          download of information or materials through the internet service
          provided.</font></font></font></p>
          <p style="margin-bottom: 0.09in; line-height: 0.2in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">Use
          of the wireless network is subject to the general restrictions
          outlined below. If abnormal, illegal, or unauthorized behavior is
          detected, including heavy consumption of bandwidth, the network
          provider reserves the right to permanently disconnect the offending
          device from the wireless network.</font></font></font></p>
          <p style="margin-bottom: 0.09in; line-height: 0.2in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt"><br/><b>Examples
          of Illegal Uses</b></font></font></font></p>
          <p style="margin-bottom: 0.09in; line-height: 0.2in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">The
          following are representative examples only and do not comprise a
          comprehensive list of illegal uses:</font></font></font></p>
          <ol style="font-size: 14px; line-height: 28px;">
            <li><p style="margin-top: 0.19in; margin-bottom: 0in; line-height: 0.19in">
            <font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">Spamming
            and invasion of privacy - Sending of unsolicited bulk and/or
            commercial messages over the Internet using the Service or using the
            Service for activities that invade another's privacy.</font></font></font></p>
            <li><p style="margin-bottom: 0in; line-height: 0.19in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">Intellectual
            property right violations - Engaging in any activity that infringes
            or misappropriates the intellectual property rights of others,
            including patents, copyrights, trademarks, service marks, trade
            secrets, or any other proprietary right of any third party.</font></font></font></p>
            <li><p style="margin-bottom: 0in; line-height: 0.19in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">Accessing
            illegally or without authorization computers, accounts, equipment or
            networks belonging to another party, or attempting to
            penetrate/circumvent security measures of another system. This
            includes any activity that may be used as a precursor to an
            attempted system penetration, including, but not limited to, port
            scans, stealth scans, or other information gathering activity.</font></font></font></p>
            <li><p style="margin-bottom: 0in; line-height: 0.19in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">The
            transfer of technology, software, or other materials in violation of
            applicable export laws and regulations.</font></font></font></p>
            <li><p style="margin-bottom: 0in; line-height: 0.19in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">Export
            Control Violations</font></font></font></p>
            <li><p style="margin-bottom: 0in; line-height: 0.19in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">Using
            the Service in violation of applicable law and regulation,
            including, but not limited to, advertising, transmitting, or
            otherwise making available ponzi schemes, pyramid schemes,
            fraudulently charging credit cards, pirating software, or making
            fraudulent offers to sell or buy products, items, or services.</font></font></font></p>
            <li><p style="margin-bottom: 0in; line-height: 0.19in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">Uttering
            threats;</font></font></font></p>
            <li><p style="margin-bottom: 0in; line-height: 0.19in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">Distribution
            of pornographic materials to minors;</font></font></font></p>
            <li><p style="margin-bottom: 0.19in; line-height: 0.19in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">and
            Child pornography.</font></font></font></p>
          </ol>
          <p style="margin-bottom: 0.09in; line-height: 0.2in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt"><br/><b>Examples
          of Unacceptable Uses</b></font></font></font></p>
          <p style="margin-bottom: 0.09in; line-height: 0.2in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">The
          following are representative examples only and do not comprise a
          comprehensive list of unacceptable uses:</font></font></font></p>
          <ol style="font-size: 14px; line-height: 28px;">
            <li><p style="margin-top: 0.19in; margin-bottom: 0in; line-height: 0.19in">
            <font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">High
            bandwidth operations, such as large file transfers and media sharing
            with peer-to-peer programs (i.e.torrents)</font></font></font></p>
            <li><p style="margin-bottom: 0in; line-height: 0.19in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">Obscene
            or indecent speech or materials</font></font></font></p>
            <li><p style="margin-bottom: 0in; line-height: 0.19in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">Defamatory
            or abusive language</font></font></font></p>
            <li><p style="margin-bottom: 0in; line-height: 0.19in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">Using
            the Service to transmit, post, upload, or otherwise making available
            defamatory, harassing, abusive, or threatening material or language
            that encourages bodily harm, destruction of property or harasses
            another.</font></font></font></p>
            <li><p style="margin-bottom: 0in; line-height: 0.19in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">Forging
            or misrepresenting message headers, whether in whole or in part, to
            mask the originator of the message.</font></font></font></p>
            <li><p style="margin-bottom: 0in; line-height: 0.19in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">Facilitating
            a Violation of these Terms of Use</font></font></font></p>
            <li><p style="margin-bottom: 0in; line-height: 0.19in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">Hacking</font></font></font></p>
            <li><p style="margin-bottom: 0in; line-height: 0.19in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">Distribution
            of Internet viruses, Trojan horses, or other destructive activities</font></font></font></p>
            <li><p style="margin-bottom: 0in; line-height: 0.19in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">Distributing
            information regarding the creation of and sending Internet viruses,
            worms, Trojan horses, pinging, flooding, mail-bombing, or denial of
            service attacks. Also, activities that disrupt the use of or
            interfere with the ability of others to effectively use the node or
            any connected network, system, service, or equipment.</font></font></font></p>
            <li><p style="margin-bottom: 0in; line-height: 0.19in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">Advertising,
            transmitting, or otherwise making available any software product,
            product, or service that is designed to violate these Terms of Use,
            which includes the facilitation of the means to spam, initiation of
            pinging, flooding, mail-bombing, denial of service attacks, and
            piracy of software.</font></font></font></p>
            <li><p style="margin-bottom: 0in; line-height: 0.19in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">The
            sale, transfer, or rental of the Service to customers, clients or
            other third parties, either directly or as part of a service or
            product created for resale.</font></font></font></p>
            <li><p style="margin-bottom: 0in; line-height: 0.19in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">Seeking
            information on passwords or data belonging to another user.</font></font></font></p>
            <li><p style="margin-bottom: 0in; line-height: 0.19in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">Making
            unauthorized copies of proprietary software, or offering
            unauthorized copies of proprietary software to others.</font></font></font></p>
            <li><p style="margin-bottom: 0.19in; line-height: 0.19in"><font color="#2b2b2b"><font face="Helvetica Neue, serif"><font size="1" style="font-size: 12pt">Intercepting
            or examining the content of messages, files or communications in
            transit on a data network.</font></font></font></p>
          </ol>
          <p style="margin-bottom: 0in; line-height: 100%"><br/></p>
    `,
        }}
      />
    </>
  );
}
