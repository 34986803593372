import {
  POS_SET_USER_INFO,
  POS_TOGGLE_SIDENAV,
  POS_SET_PR_CATEGORIES,
  POS_SET_JP_CATEGORIES,
  POS_SET_LOCATIONS,
  POS_SELECT_CATEGORY,
  POS_SELECT_ITEM,
  POS_ADD_TO_CART,
  POS_DELETE_FROM_CART,
  POS_EMPTY_CART,
  POS_SET_TOKEN,
  POS_SET_APP_COMPONENT,
  POS_SET_ITEMS,
  POS_RESET_STATE,
  POS_FILTER_CATEGORIES,
  POS_SELECT_SUB_CATEGORY,
  POS_SET_FILTER_MODE,
  POS_FILTER_ITEMS,
  POS_FILTER_JP_CATEGORIES,
  POS_SET_HP_CATEGORIES,
} from "../types";

const initialState = {
  userInfo: {},
  currentComponent: "subcategories",
  isSidenav: false,
  filter_mode: false,
  error: null,
  access_token: null,
  categories: [],
  subCategories: [],
  filtered_categories: [],
  items: [],
  locations: [],
  accessPoints: [],
  selected_category: null,
  selected_subCategory: null,
  selected_item: null,
  orders: [],
  cartItems: [],
  taxes: [],
  tables: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POS_SET_USER_INFO:
      return { ...state, userInfo: action.payload };
    case POS_SET_TOKEN:
      return { ...state, access_token: action.payload };
    case POS_TOGGLE_SIDENAV:
      return { ...state, isSidenav: !state.isSidenav };
    case POS_SET_FILTER_MODE:
      return { ...state, filter_mode: !state.filter_mode };

    case POS_SET_ITEMS:
      return {
        ...state,
        items: action.payload.map((i) => ({
          ...i,
          checked: action.itemsFilter.includes(i[action.id_prop])
            ? true
            : false,
        })),
        //order_options: action.order_options ? action.order_options : null,
        taxes: action.taxes,
        tables: action.tables,
      };

    case POS_SET_PR_CATEGORIES:
      console.log(action.allItems);
      let cat_arr = [];
      action.payload.map((c) => {
        let item = action.allItems.find((i) => i.category.id === c.id);
        if (item)
          cat_arr.push({
            ...c,
            photo: item.image ? item.image["256"] : "",
            checked: action.categoriesFilter.includes(c.id) ? true : false,
          });
        return true;
      });
      return {
        ...state,
        categories: [...cat_arr],
      };

    case POS_SET_JP_CATEGORIES:
      console.log({ categoriesFilter: action.payload });
      return {
        ...state,
        categories: action.payload
          .filter((c) => c.parent_category === "0")
          .map((c) => {
            return {
              ...c,
              id: c.category_id,
              name: c.category_name,
              checked: action.categoriesFilter?.includes(c.category_id)
                ? true
                : false,
            };
          }),
        subCategories: action.payload
          .filter((c) => c.parent_category !== "0")
          .map((c) => ({
            ...c,
            id: c.category_id,
            name: c.category_name,
            photo: c.category_photo
              ? `https://joinposter.com/${c.category_photo}`
              : "",
            checked: action.categoriesFilter?.includes(c.category_id)
              ? true
              : false,
          })),
      };

    case POS_SET_HP_CATEGORIES:
      console.log({ categoriesFilter: action.payload });
      return {
        ...state,
        categories: action.payload
          .filter((c) => c.parent === 0)
          .map((c) => {
            return {
              ...c,
              id: c.id,
              name: c.name,
              checked: action.categoriesFilter?.includes(c.id) ? true : false,
            };
          }),
        subCategories: action.payload
          .filter((c) => c.parent !== 0)
          .map((c) => ({
            ...c,
            id: c.id,
            name: c.name,
            photo: c.image ? c.image : "",
            checked: action.categoriesFilter?.includes(c.id) ? true : false,
          })),
      };

    case POS_FILTER_CATEGORIES:
      console.log(
        "POS_FILTER_CATEGORIES  --> categories: ",
        state.categories,
        action.cat_type
      );
      return {
        ...state,
        categories:
          action.cat_type === "main"
            ? // change object value
              state.categories.map((c) =>
                c.id === action.cat_id
                  ? { ...c, checked: !c.checked }
                  : { ...c }
              )
            : state.categories,
        subCategories:
          action.cat_type === "sub"
            ? // change object value
              state.subCategories.map((c) =>
                c.id === action.cat_id
                  ? { ...c, checked: !c.checked }
                  : { ...c }
              )
            : state.subCategories,
      };

    case POS_FILTER_JP_CATEGORIES:
      return {
        ...state,
        filtered_categories: action.cat_arr
          ? // filter using the array
            state.categories.map((c) => ({
              id: c.category_id,
              name: c.category_name,
              checked: action.cat_arr.includes(c.category_name) ? true : false,
            }))
          : // change object value
            state.filtered_categories.map((c) =>
              c.name === action.cat_name
                ? { ...c, checked: !c.checked }
                : { ...c }
            ),
      };

    case POS_FILTER_ITEMS:
      return {
        ...state,
        //filtered_categories:
        items:
          // change object value
          state.items.map((i) =>
            i[action.id_prop] === action.item_id
              ? { ...i, checked: !i.checked }
              : { ...i }
          ),
      };
    case POS_SET_LOCATIONS:
      return {
        ...state,
        locations: action.locations,
        accessPoints: action.accessPoints,
      };
    case POS_SELECT_CATEGORY:
      console.log("selected_category: ", action.payload);
      return {
        ...state,
        selected_category: action.payload
          ? action.payload
          : state.selected_category,
      };
    case POS_SELECT_SUB_CATEGORY:
      return {
        ...state,
        selected_subCategory: action.payload
          ? action.payload
          : state.selected_subCategory,
      };
    case POS_SELECT_ITEM:
      return { ...state, selected_item: action.payload };
    case POS_ADD_TO_CART:
      return {
        ...state,
        orders: [action.payload.item, ...state.orders],
        cartItems: [...state.cartItems, action.payload.cart],
      };
    case POS_DELETE_FROM_CART:
      return {
        ...state,
        cartItems: state.cartItems.filter((item) => item.id !== action.payload),
      };
    case POS_EMPTY_CART:
      return { ...state, cartItems: [], orders: [] };
    case POS_SET_APP_COMPONENT:
      return { ...state, currentComponent: action.payload };
    case POS_RESET_STATE:
      return { ...state, locations: [], accessPoints: [] };
    default:
      return state;
  }
};

export default reducer;
