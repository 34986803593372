import { createBrowserHistory } from 'history';
import { Route } from 'react-router-dom';

import {
    // or createReactRouterV4Options
    createReactRouterV5Options,
    getWebInstrumentations,
    initializeFaro,
    ReactIntegration,
    ReactRouterVersion,
} from '@grafana/faro-react';

import { TracingInstrumentation } from '@grafana/faro-web-tracing';


const history = createBrowserHistory();

const user = {
    id: localStorage.getItem('userId'),
    email: localStorage.getItem('email'),
}
const instrumentationOptions = {
    propagateTraceHeaderCorsUrls: [/^https:\/\/(?:[a-zA-Z0-9-]+\.)*hoopoe\.digital\/.*$/], // This is a list of specific URIs or regular exprressions
};

initializeFaro({
    trackWebVitalsAttribution: true,
    // Mandatory, the URL of the Grafana collector
    url: process.env.REACT_APP_ALLOY_COLLECTOR_URL,

    // Mandatory, the identification label of your application
    app: {
        name: 'wmc-frontend',
        environment: process.env.REACT_APP_ENV || 'local',
    },

    // ...

    instrumentations: [
        // Load the default Web instrumentations
        ...getWebInstrumentations({
            captureConsole: true,
        }),
        new TracingInstrumentation({ instrumentationOptions }),
        new ReactIntegration({
            // or createReactRouterV4Options
            router: createReactRouterV5Options({
                history, // the history object used by react-router
                Route, // Route component imported from react-router package
            }),
        }),

    ],
    user
});