import {
  ANALYTIC_START,
  ANALYTIC_MSG,
  GET_MODULES,
  GET_CHARTS,
  ADD_MODULE,
  REMOVE_MODULE,
  SET_FILTER,
  SET_GROUPS,
  SET_APS,
  SET_ANALYTICS_APP_DATA,
} from "../types";

const initialState = {
  isLoading: false,
  msg: null,
  moduleName: [
    "Device Info",
    "User Info",
    "General Info",
    "Sessions",
    "Digital Menu",
    "e-Queuing",
    "Meraki",
    "HEPlex",
    // "Location Data",
    // "Business Intelligence",
  ],
  modules: [],
  charts: [],
  selectedGroups: [],
  selectedAPs: [],
  filter: {
    captivePortal: 0,
    cpType: "",
    interval: "today",
    custom: true,
    fromDate: `${new Date().toJSON().slice(0, 10)}`,
    toDate: `${new Date().toJSON().slice(0, 10)}`,
    macs: [],
    type: "Registered",
    appID: "none",
  },
  appData: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ANALYTIC_START:
      return {
        ...state,
        isLoading: true,
        msg: null,
      };
    case ANALYTIC_MSG:
      return {
        ...state,
        isLoading: false,
        msg: action.payload,
      };
    case GET_MODULES:
      return {
        ...state,
        modules: action.payload,
      };
    case ADD_MODULE:
      return {
        ...state,
        modules: [...state.modules, { ModuleID: action.payload }],
      };
    case REMOVE_MODULE:
      state.modules.splice(
        state.modules.findIndex((e) => e.ModuleID === action.payload),
        1
      );
      return {
        ...state,
      };
    case GET_CHARTS:
      return {
        ...state,
        charts: action.payload,
      };
    case SET_GROUPS:
      return {
        ...state,
        selectedGroups: action.payload,
      };
    case SET_APS:
      return {
        ...state,
        selectedAPs: action.payload,
      };
    case SET_FILTER:
      return {
        ...state,
        filter: { ...action.payload },
      };
    case SET_ANALYTICS_APP_DATA:
      return {
        ...state,
        appData: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
