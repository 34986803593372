import axios from "axios";
import {
  JP_SET_USER_INFO,
  JP_TOGGLE_SIDENAV,
  JP_SET_CATEGORIES,
  JP_SET_POS_ITEMS,
  JP_SET_LOCATIONS,
  JP_SELECT_CATEGORY,
  JP_SELECT_SUB_CATEGORY,
  JP_SELECT_ITEM,
  JP_ADD_TO_CART,
  JP_DELETE_FROM_CART,
  JP_EMPTY_CART,
  JP_SET_POSROCKET_TOKEN,
  JP_SET_APP_COMPONENT,
  JP_RESET_STATE,
  JP_FILTER_CATEGORIES,
} from "../types";

export const JP_setUser = (user) => {
  return { type: JP_SET_USER_INFO, payload: user };
};

export const JP_reset_state = () => {
  return { type: JP_RESET_STATE };
};

export const JP_setAppComponent = (current) => {
  return { type: JP_SET_APP_COMPONENT, payload: current };
};

export const JP_setToken = (token) => {
  return { type: JP_SET_POSROCKET_TOKEN, payload: token };
};

export const JP_toggleSidenav = () => {
  return { type: JP_TOGGLE_SIDENAV };
};

export const JP_selectCategory = (id) => {
  return { type: JP_SELECT_CATEGORY, payload: id };
};

export const JP_filterCategories = (cat_arr = null, cat_name = null) => {
  return { type: JP_FILTER_CATEGORIES, cat_arr, cat_name };
};

export const JP_selectSubCategory = (id) => {
  return { type: JP_SELECT_SUB_CATEGORY, payload: id };
};

export const JP_selectItem = (id) => {
  return { type: JP_SELECT_ITEM, payload: id };
};

export const JP_addToCart = (item, name, price, quantity, rate) => {
  let cart = { id: item.id, name, price, quantity, rate };
  return { type: JP_ADD_TO_CART, payload: { item, cart } };
};

export const JP_deleteFromCart = (itemId) => {
  return { type: JP_DELETE_FROM_CART, payload: itemId };
};

export const JP_emptyCart = () => {
  return { type: JP_EMPTY_CART };
};

export const JP_update_locations_aps = (locations, accessPoints, cp_id) => {
  return {
    type: JP_SET_LOCATIONS,
    locations,
    accessPoints: cp_id
      ? accessPoints.filter((ap) => ap.cp_id === cp_id || ap.cp_id === null)
      : accessPoints.filter((ap) => ap.cp_id === null),
  };
};

//fetch the data of categories and all (location based) items
export const JP_getAllItems = (location = "dsfsfsd") => {
  console.log(location);
  return async (dispatch, getState) => {
    try {
      let { categoriesFilter } = getState().cpEditor;
      let [categories, allItems, taxes, tables] = await Promise.all([
        axios({
          method: "get",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pr_token")}`,
          },
          url: `${process.env.REACT_APP_API_URL}/joinposterapi/list-categories`,
        }),
        axios({
          method: "post",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pr_token")}`,
          },
          url: `${process.env.REACT_APP_API_URL}/joinposterapi/list-items`,
          data: { location_id: location },
        }),
        axios({
          method: "get",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pr_token")}`,
          },
          url: `${process.env.REACT_APP_API_URL}/joinposterapi/gettaxes`,
        }),
        axios({
          method: "get",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pr_token")}`,
          },
          url: `${process.env.REACT_APP_API_URL}/joinposterapi/get-all-tables`,
        }),
      ]);
      dispatch({ type: JP_SET_CATEGORIES, payload: categories.data.data });
      dispatch(JP_filterCategories(categoriesFilter, null));
      dispatch({
        type: JP_SELECT_CATEGORY,
        payload: categories.data.data[0].category_id,
      });
      dispatch({ type: JP_SET_POS_ITEMS, payload: allItems.data.data });
      dispatch({
        type: JP_SET_POS_ITEMS,
        payload: allItems.data.data,
        taxes: taxes.data.data,
        tables: tables.data.data,
      });
    } catch (e) {
      console.log(e);
    }
  };
};

// get locations and access points to connect them together
export const JP_handleLocations = (cp_id = "") => {
  return async (dispatch) => {
    try {
      let [accessPoints, locations] = await Promise.all([
        axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/network/accesscontrollers/all`,
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }),
        axios({
          method: "get",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pr_token")}`,
          },
          url: `${process.env.REACT_APP_API_URL}/joinposterapi/get-locations`,
        }),
      ]);
      //dispatch(JP_getAllItems(locations.data.data[0].id));
      dispatch(JP_getAllItems());
      dispatch(
        JP_update_locations_aps(locations.data.data, accessPoints.data, cp_id)
      );
      //dispatch({ type: SET_LOCATIONS, locations: locations.data.data, accessPoints: accessPoints.data })
    } catch (e) {
      console.log(e);
    }
  };
};
