import axios from "axios";
import {
  DASHBOARD_START,
  DASHBOARD_MSG,
  GET_REGISTERED,
  GET_ONLINE,
  GET_ACCESSPOINTS,
  GET_DOWNLOAD,
  GET_UPLOAD,
  SHOW_ONLINE,
  SHOW_REGISTERED,
  SHOW_LOCATIONS,
  SET_GENERATED_SESSIONS,
  SET_REGISTERED_DEVICES,
  SHOW_DEVICE_INFO,
  SET_AVG_SESSION_DURATION,
  SET_AVG_GB_PER_DAY,
  SET_ONLINE_DEVICES,
  SHOW_ONLINE_DEVICE_INFO,
  SHOW_AP_STATUS,
  SET_SMS_CONSUMPTION,
} from "../types";

// Actions
function start() {
  return { type: DASHBOARD_START };
}
function analyticMsg(payload) {
  return { type: DASHBOARD_MSG, payload };
}
function setOnline(users) {
  return { type: GET_ONLINE, payload: users };
}
function setRegistered(users) {
  return { type: GET_REGISTERED, payload: users };
}
function setOnlineDevices(devices) {
  return { type: SET_ONLINE_DEVICES, payload: devices };
}
function setRegisteredDevices(devices) {
  return { type: SET_REGISTERED_DEVICES, payload: devices };
}

function setAvgSessionDuration(duration) {
  return { type: SET_AVG_SESSION_DURATION, payload: duration };
}
function setAvgGBPerDay(duration) {
  return { type: SET_AVG_GB_PER_DAY, payload: duration };
}
function setAccessPoints(aps) {
  return { type: GET_ACCESSPOINTS, payload: aps };
}
function setDownloadQuota(quota) {
  return { type: GET_DOWNLOAD, payload: quota };
}
function setUploadQuota(quota) {
  return { type: GET_UPLOAD, payload: quota };
}
function setGeneratedSessions(sessions) {
  return { type: SET_GENERATED_SESSIONS, payload: sessions };
}
function setSMSConsumption(sms) {
  return { type: SET_SMS_CONSUMPTION, payload: sms };
}
export function toggleShowOnline() {
  return { type: SHOW_ONLINE };
}
export function toggleShowRegistered() {
  return { type: SHOW_REGISTERED };
}
export function toggleShowDeviceInfo() {
  return { type: SHOW_DEVICE_INFO };
}
export function toggleShowOnlineDeviceInfo() {
  return { type: SHOW_ONLINE_DEVICE_INFO };
}
export function toggleShowLocations() {
  return { type: SHOW_LOCATIONS };
}
export function toggleShowAPStatus() {
  return { type: SHOW_AP_STATUS };
}
export const getOnlineUsers = () => {
  return (dispatch) => {
    dispatch(start());
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/dashboard/onlineusers`,
    })
      .then((result) => {
        // console.log(result.data.data);
        dispatch(setOnline(result.data.data));
      })
      .catch((err) => {
        dispatch(analyticMsg({ type: "error", msg: err }));
      });
  };
};
export const getOnlineDevices = () => {
  return (dispatch) => {
    dispatch(start());
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/dashboard/onlinedevices`,
    })
      .then((result) => {
        // console.log(result.data.data);
        dispatch(setOnlineDevices(result.data.data));
      })
      .catch((err) => {
        dispatch(analyticMsg({ type: "error", msg: err }));
      });
  };
};
export const getRegisteredDevices = () => {
  return (dispatch) => {
    dispatch(start());
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/dashboard/registereddevices`,
    })
      .then((result) => {
        // console.log(result.data.data);
        dispatch(setRegisteredDevices(result.data.data));
      })
      .catch((err) => {
        dispatch(analyticMsg({ type: "error", msg: err }));
      });
  };
};
export const getAvgSessionDuration = () => {
  return (dispatch) => {
    dispatch(start());
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/dashboard/avgsessionduration`,
    })
      .then((result) => {
        // console.log(result.data.data);
        dispatch(setAvgSessionDuration(result.data.data));
      })
      .catch((err) => {
        dispatch(analyticMsg({ type: "error", msg: err }));
      });
  };
};
export const getAvgGBPerDay = () => {
  return (dispatch) => {
    dispatch(start());
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/dashboard/avggbperday`,
    })
      .then((result) => {
        // console.log(result.data.data);
        dispatch(setAvgGBPerDay(result.data.data));
      })
      .catch((err) => {
        dispatch(analyticMsg({ type: "error", msg: err }));
      });
  };
};
export const getGeneratedSessions = () => {
  return (dispatch) => {
    dispatch(start());
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/dashboard/generatedsessions`,
    })
      .then((result) => {
        // console.log(result.data.data);
        dispatch(setGeneratedSessions(result.data.data));
      })
      .catch((err) => {
        dispatch(analyticMsg({ type: "error", msg: err }));
      });
  };
};

export const getAccessPoints = () => {
  return (dispatch) => {
    dispatch(start());
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/dashboard/accesspoints`,
    })
      .then((result) => {
        // console.log(result.data);
        dispatch(setAccessPoints(result.data.data));
      })
      .catch((err) => {
        dispatch(analyticMsg({ type: "error", msg: err }));
      });
  };
};

export const getRegisteredUsers = () => {
  return (dispatch) => {
    dispatch(start());
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/dashboard/registeredusers`,
    })
      .then((result) => {
        // console.log(result.data);
        dispatch(setRegistered(result.data.data));
      })
      .catch((err) => {
        dispatch(analyticMsg({ type: "error", msg: err }));
      });
  };
};

export const getDownloadQuota = () => {
  return (dispatch) => {
    dispatch(start());
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/dashboard/downloadquota`,
    })
      .then((result) => {
        // console.log(result.data);
        dispatch(setDownloadQuota(result.data.data));
      })
      .catch((err) => {
        dispatch(analyticMsg({ type: "error", msg: err }));
      });
  };
};

export const getUploadQuota = () => {
  return (dispatch) => {
    dispatch(start());
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/dashboard/uploadquota`,
    })
      .then((result) => {
        // console.log(result.data);
        dispatch(setUploadQuota(result.data.data));
      })
      .catch((err) => {
        dispatch(analyticMsg({ type: "error", msg: err }));
      });
  };
};
export const getSMSConsumption = () => {
  return (dispatch) => {
    dispatch(start());
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/dashboard/smsconsumption`,
    })
      .then((result) => {
        // console.log(result.data);
        dispatch(setSMSConsumption(result.data.data));
      })
      .catch((err) => {
        dispatch(analyticMsg({ type: "error", msg: err }));
      });
  };
};
