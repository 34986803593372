import { combineReducers } from "redux";
import templateReducer from "./templateReducer";
import authReducer from "./authReducer";
import profileReducer from "./profileReducer";
import cpEditorReducer from "./cpEditorReducer";
import cloudReducer from "./cloudReducer";
import analyticsConfigReducer from "./analyticsConfigReducer";
import analyticsReducer from "./analyticsReducer";
import voucherReducer from "./voucherReducer";
import posRocketApp from "./posRocketAppReducer";
import joinPosterApp from "./joinPosterAppReducer";
import dashboardReducer from "./dashboardReducer";
import networkReducer from "./networkReducer";
import posReducer from "./posReducer";
import appControlReducer from "./appControlReducer";
import digitalMenuPosReducer from "./digitalMenuPosReducer";
import marketplaceReducer from "./marketplaceReducer";
import googleReducer from "./googleReducer";
import docsReducer from "./docsReducer";

export default combineReducers({
  template: templateReducer,
  auth: authReducer,
  profile: profileReducer,
  network: networkReducer,
  cpEditor: cpEditorReducer,
  cloud: cloudReducer,
  analytics: analyticsConfigReducer,
  theAnalytics: analyticsReducer,
  voucher: voucherReducer,
  posRocketApp: posRocketApp,
  joinPosterApp: joinPosterApp,
  dashboard: dashboardReducer,
  pos: posReducer,
  digitalMenuPos: digitalMenuPosReducer,
  app: appControlReducer,
  marketplace: marketplaceReducer,
  google: googleReducer,
  docs: docsReducer,
});
