import axios from "axios";
import { toast } from "react-toastify";
import { getCaptivePortals } from "./cloudActions";
import {
  SET_QS_QUEUE,
  SET_DB_BRANCHES,
  SET_DB_BRANCH,
  SET_DB_UPDATETIME,
  SET_DB_COUNTER,
  //TOGGLE_NEW_ORDER,
  SET_MODAL_ITEM,
  SET_ORDERS_DATA,
  SET_RESTAURANT_NAME,
  SET_NEW_ORDERS,
  MAKE_ORDERS_SEEN,
  //SET_LOGS_CP,
  SAVE_LOGS,
  CHANGE_LOGS_FILTER,
  RESET_QS_STATE,
  CHANGE_WIFI_AUTH_COMP,
  TRIGGER_NOTIFICATION,
  DOARAK_SET_APP,
} from "../types";

// Actions
// export const setCloudLoading = (val) => {
//   return { type: CLOUD_LOADING, payload: val };
// };
// export const cloudError = (err) => {
//   return { type: CLOUD_ERROR, error: err };
// };
// export const addCP = (cps) => {
//   return { type: ADD_CP, payload: cps };
// };
// export const setOffers = (offers) => {
//   return { type: GET_OFFERS, payload: offers };
// };

export const triggerNotifications = (type) => {
  return { type: TRIGGER_NOTIFICATION, payload: type };
};

export const changeWiFiAuthComp = (id) => {
  return { type: CHANGE_WIFI_AUTH_COMP, payload: id };
};
export const setApp = (id) => {
  return { type: DOARAK_SET_APP, payload: id };
};
export const setQueue = (queue) => {
  return (dispatch, getState) => {
    if (getState().app.queue.length < queue.length) {
      dispatch(playSound("/assets/images/insight-578.mp3"));
    }
    dispatch({ type: SET_QS_QUEUE, payload: queue });
    dispatch({ type: SET_DB_UPDATETIME });
  };
};
export const setUpdated = () => {
  return { type: SET_DB_UPDATETIME };
};
export const setBranch = (branch) => {
  console.log(branch);
  return (dispatch, getState) => {
    dispatch({ type: SET_DB_BRANCH, payload: branch });
    dispatch(getQueue(getState().app.cpid, branch));
  };
};
export const setModalItem = (id) => {
  return { type: SET_MODAL_ITEM, payload: id };
};
export const resetQSState = (state) => {
  return (dispatch) => {
    dispatch({ type: RESET_QS_STATE, state });
    dispatch(getQueue());
  };
};
export const toggleNewOrder = (data) => {
  return (dispatch, getState) => {
    console.log({ data });
    if (data.phone) {
      console.log("should play the sound for chatbot menu");
      dispatch(playSound("/assets/images/insight-578.mp3"));
    }

    let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    //Replacing the output to look like mysql datetime entry
    let time = new Date(Date.now() - tzoffset)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    let newOrder = {
      id: getState().app.newOrders.length,
      time: time,
      seen: false,
      phone: data.phone ? data.phone : data.user,
    };
    dispatch({ type: SET_NEW_ORDERS, payload: newOrder });
  };
};
export const setTableData = (orders) => {
  return { type: SET_ORDERS_DATA, payload: orders };
};
export const makeSeen = () => {
  return { type: MAKE_ORDERS_SEEN };
};
export const setRestaurantName = (name) => {
  return { type: SET_RESTAURANT_NAME, payload: name };
};
export const changeLogsFilter = (prop, value) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_LOGS_FILTER, prop, value });
  };
};
// export const setAPGroups = (apgroups) => {
//   return { type: GET_APGROUPS, payload: apgroups };
// };
// export const setCurrentComponent = (current) => {
//   return { type: SET_COMPONENT, payload: current };
// };
export const setAppDetails = (
  branches,
  cpid,
  app,
  appDetails,
  selectedGateway
) => {
  return {
    type: SET_DB_BRANCHES,
    cpid,
    app,
    branches,
    appDetails,
    selectedGateway,
  };
};
export const setRule = (rule) => {
  return async (dispatch, getState) => {
    try {
      console.log(rule);
      let app = getState().cloud.captivePortals.filter(
        (cp) => cp.ID === parseInt(getState().app.cpid)
      )[0];
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/cloud/updateapp`,
          { app, rule },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(function (response) {
          console.log(response.data);
          dispatch(getCaptivePortals());
          dispatch(setApp(response.data.data.app));
          toast.success("Updated successfully");
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const setCounter = (c) => {
  return (dispatch) => {
    dispatch({ type: SET_DB_COUNTER, payload: c });
    dispatch(getQueue());
  };
};

export const setDashboardBranches = (id) => {
  return (dispatch, getState) => {
    if (parseInt(id)) {
      let app = getState().cloud.captivePortals.filter(
        (cp) => cp.ID === parseInt(id)
      )[0];
      dispatch(
        setAppDetails(
          app.config.branches,
          id,
          app,
          app.config.appDetails,
          app.config.selectedGateway
        )
      );
    } else {
      dispatch(setAppDetails([], id, {}));
    }
  };
};

export function playSound(url) {
  return (dispatch) => {
    if (!url) url = "/assets/images/insight-578.mp3";
    console.log(url);
    const audio = new Audio(url);
    audio.play().catch((err) => console.log(err));
  };
}

export const getQueue = (cpid, branchId) => {
  return (dispatch, getState) => {
    console.log(cpid, branchId);
    cpid = cpid ? cpid : getState().app.cpid;
    branchId = branchId ? branchId : getState().app.branch;
    axios({
      method: "post",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/qs/getqueue`,
      data: { cpid, branchId },
    })
      .then((result) => {
        console.log(result.data.queue.length);
        dispatch(setQueue(result.data.queue));
      })
      .catch((err) => {
        console.log(err);
      });
    // .finally(() => {
    //   dispatch(setCloudLoading(false));
    // });
  };
};

export const serveMember = (serviceId, counter, externalOutput) => {
  console.log(serviceId);
  let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  //Replacing the output to look like mysql datetime entry
  let time = new Date(Date.now() - tzoffset)
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");
  return (dispatch, getState) => {
    // dispatch(setQSLoading(true));
    const cpid = parseInt(getState().app.cpid);
    const branchId = getState().app.branch;
    const branches = getState().app.branches;
    if (!getState().app.counter) {
      toast.warn("You need to select a counter");
      throw new Error("Please Select a counter");
    }

    // get the screen mac if any
    const screens = branches?.find((b) => b.id === branchId)?.screens;
    let screen = undefined;
    if (screens && screens.length) {
      let s = screens.find((s) => s.counter === counter);
      screen = s && s.screen ? s.screen.mac : undefined;
    }

    const endPoint = externalOutput ? "handleserving" : "servemember";

    axios({
      method: "post",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/qs/${endPoint}`,
      data: { serviceId, time, cpid, branchId, counter, screen },
    })
      .then((result) => {
        //dispatch(getQueue());
        toast.success(result.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

//
export const queryFilter = () => {
  return (dispatch, getState) => {
    let filter = getState().app.logsFilter;
    axios
      .post(`${process.env.REACT_APP_API_URL}/qs/getlogs`, filter, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        console.log(res.data.data);
        dispatch({ type: SAVE_LOGS, payload: res.data.data });
        dispatch(setUpdated());
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const transferQueue = (s, t) => {
  return (dispatch, getState) => {
    console.log(s, t);
    let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    //Replacing the output to look like mysql datetime entry
    let time = new Date(Date.now() - tzoffset)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/qs/transferQueue`,
        { serviceId: s, ticket: t, time },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        console.log(res.data.data);
        // dispatch({ type: SAVE_LOGS, payload: res.data.data });
        toast.success("Transfered successfully");
        dispatch(setUpdated());
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getCounterQueue = () => {
  return (dispatch, getState) => {
    console.log(getState().app);
    let { cpid, branch, counter } = getState().app;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/qs/getcounterqueue`,
        { cpid, branch, counter },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        console.log(res.data.data);
        // dispatch({ type: SAVE_LOGS, payload: res.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

/*

export const serveMember_old = (serviceId, counter) => {
  console.log(serviceId);
  let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  //Replacing the output to look like mysql datetime entry
  let time = new Date(Date.now() - tzoffset)
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");
  return (dispatch, getState) => {
    // dispatch(setQSLoading(true));
    const cpid = parseInt(getState().app.cpid);
    const branchId = getState().app.branch;
    if (!getState().app.counter) {
      toast.warn("You need to select a counter");
      throw "Please Select a counter";
    }
    axios({
      method: "post",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/qs/servemember`,
      data: { serviceId, time, cpid, branchId, counter },
    })
      .then((result) => {
        dispatch(getQueue());
        toast.success(result.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

*/


/*
export const getData = () => {
  return async (dispatch, getState) => {
    console.log("fetching data");
    try {
      const getRestuarantname = await axios.get(
        `https://api.airtable.com/v0/appBo35NC6XMPV58U/Restaurants?filterByFormula=WMC_Id=${localStorage.getItem(
          "userId"
        )}`,
        {
          headers: {
            Authorization: "Bearer keyDCV6HYZe6JFRDr",
          },
        }
      );
      console.log(getRestuarantname.data);
      if (getRestuarantname.data.records.length > 0) {
        const res = await axios.get(
          `https://api.airtable.com/v0/appBo35NC6XMPV58U/Orders?filterByFormula=(LOWER(Restaurant)="${getRestuarantname.data.records[0].fields.Name.toLowerCase()}")&sort[0][field]=Id&sort[0][direction]=desc`,
          {
            headers: {
              Authorization: "Bearer keyDCV6HYZe6JFRDr",
            },
          }
        );
        if (res.data.records.length > 0) {
          console.log("hello");
          let arr = [];
          res.data.records.map((r) => {
            for (const [key, value] of Object.entries(r.fields)) {
              r[key] = value;
            }
            console.log(r);
            r.CustomerName = `${JSON.parse(r.CustomerDetails).Name}`;
            r.CustomerPhone = `${JSON.parse(r.CustomerDetails).Phone}`;
            let time = new Date(r.createdTime).toUTCString();
            r.createdTime = time.slice(0, 22);
            arr.push(r);
            return false;
          });
          console.log(arr);
          dispatch(setRestaurantName(arr[0].fields.Restaurant));
          dispatch(setTableData(arr));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const changeUserAddress = (order_id, user_id, address) => {
  return (dispatch) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/qs/updateaddress`,
        { order_id, user_id, address },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        dispatch(getData());
        toast.success("Updated successfully");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const changeOrderStatus = (status, id) => {
  return (dispatch) => {
    console.log(status);
    console.log(id);
    var data = JSON.stringify({
      records: [{ id: id, fields: { Status: status } }],
    });
    axios
      .patch("https://api.airtable.com/v0/appBo35NC6XMPV58U/Orders", data, {
        headers: {
          Authorization: "Bearer keyDCV6HYZe6JFRDr",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        dispatch(getData());
        toast.success("Updated successfully");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

*/
