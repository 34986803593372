import {
  SET_NAME,
  CHANGE_LOGO,
  CHANGE_DM_LOGO,
  CHANGE_DM_LOGINBG,
  CHANGE_TITLE,
  CHANGE_THEME,
  CHANGE_DESC,
  CHANGE_FORMDETAILS,
  SET_QS_BRANCHFORM,
  ACTIVE_TAB,
  EDIT_QS_BRANCH,
  SET_HOST_MAC,
  CHANGE_TAB,
  ASSIGN_SSIDS,
  SET_SPOTIFY_TOKEN,
  ASSIGN_VOUCHERS,
  RESET_FORMDETAILS,
  RESET_STATE,
  SET_OFFER,
  SET_FALL_BACK,
  SET_DISABLE_ADS,
  SET_ID,
  SET_LOGO,
  CHANGE_BG,
  SET_BG,
  CHANGE_SIGNUP_METHODS,
  CHANGE_SIGNUP_METHOD_OFFER,
  RESET_SIGNUP_METHODS,
  CHANGE_POWEREDBY,
  SET_TOKEN,
  TABS_FOR_EDIT,
  SET_LOCATIONS_POINTS,
  SET_MENU_FILTER,
  SET_QS_SERVICES,
  SET_QS_BRANCHES,
  REMOVE_QS_BRANCH,
  CHANGE_LOGIN_BG,
  SET_SELECTED_GATEWAY,
  SET_DEFAULT_LANGUAGE,
  TOGGLE_NAME_VALUE,
  CHANGE_BYPASS_LANDING,
} from "../types";

export const themeProps = {
  t1: {
    validProps: {
      particleComponent: true,
      cssBackgroundColor: true,
      fontColor: true,
      btnColor: true,
      btnFontColor: true,
      btnBgColor: true,
      logoBorder: true,
      logoBorderColor: true,
      textFieldBgColor: true,
      textFieldFontColor: true,
    },
    logo: `${process.env.REACT_APP_QUEUE_ENDPOINT}/assets/images/basic.png`,
    dynamicBg: {
      colorValue: "#bf844d",
      line_linkedEnable: true,
      line_linkedColor: "#bf844d",
      numberValue: 80, //0 --> 600
      numberDensity: 800, //600 --> 10000
      sizeValue: 5, //0 --> 500
      moveEnable: true,
      moveSpeed: 1.3, //0 --> 50
      moveDirection: "none",
      cssBackgroundColor: "#20242c",
      fontColor: "#ffffff",
      btnColor: "#e7a15e",
      btnFontColor: "#ffffff",
      btnBgColor: "transparent",
      logoBorder: 0,
      logoBorderColor: "transparent",
      textFieldBgColor: "transparent",
      textFieldFontColor: "#ffffff",
    },
  },
  t2: {
    validProps: {
      particleComponent: false,
      cssBackgroundColor: true,
      fontColor: true,
      btnColor: true,
      btnFontColor: true,
      btnBgColor: true,
      textFieldBgColor: true,
      textFieldFontColor: true,
    },
    logo: `${process.env.REACT_APP_QUEUE_ENDPOINT}/assets/images/wifiIcon.png`,
    dynamicBg: {
      cssBackgroundColor: "#ffffff",
      textFieldBgColor: "transparent",
      fontColor: "#000000",
      btnBgColor: "#cc2128",
      textFieldFontColor: "#000000",
      btnFontColor: "#ffffff",
      btnColor: "#cc2128",
    },
  },
};

const initialState = {
  id: "",
  name: "",
  app_api: "posrocket",
  template: "cp",
  theme: "t1",
  hostMac: null,
  selectedGateway: "",
  defaultLanguage: "eng",
  logo: "",
  dmLogo: "",
  loginBg: `${process.env.REACT_APP_STATIC_URL}/cps-logos/foodbg1.png`,
  title: "",
  desc: "",
  poweredBy: "",
  digitalMenuToken: null,
  DM_ID: "",
  services: [
    { id: 1, name: "Customer Support", value: "A", icon: "handshake" },
    { id: 2, name: "New Customers", value: "B", icon: "users" },
    { id: 3, name: "Pay Bill", value: "C", icon: "money-bill" },
  ],
  dynamicBg: {
    colorValue: "#bf844d",
    line_linkedEnable: true,
    line_linkedColor: "#bf844d",
    numberValue: 80, //0 --> 600
    numberDensity: 800, //600 --> 10000
    sizeValue: 5, //0 --> 500
    moveEnable: true,
    moveSpeed: 1.3, //0 --> 50
    moveDirection: "none",
    cssBackgroundColor: "#20242c",
    fontColor: "#ffffff",
    btnColor: "#e7a15e",
    btnFontColor: "#ffffff",
    btnBgColor: "transparent",
    logoBorder: 0,
    logoBorderColor: "transparent",
    textFieldBgColor: "transparent",
    textFieldFontColor: "#ffff",
  },
  signupMethods: [
    { id: 2, type: "voucher", name: "Voucher", valid: false, offer: "" },
    { id: 1, type: "ad", name: "Connect", valid: false, offer: "" },
    { id: 3, type: "social", name: "phone", valid: true, offer: "" },
    { id: 4, type: "social", name: "Facebook", valid: false, offer: "" },
    { id: 5, type: "social", name: "Google", valid: false, offer: "" },
    { id: 6, type: "social", name: "Linkedin", valid: false, offer: "" },
    { id: 7, type: "social", name: "Twitter", valid: false, offer: "" },
    {
      id: 8,
      type: "social",
      name: "email",
      valid: false,
      offer: "",
      unverified_offer: "",
    },
  ],
  formDetails: [
    {
      type: "text",
      valid: false,
      name: "email",
      className: "col-12",
      required: true,
      placeholder: "Email",
    },
    {
      type: "text",
      valid: false,
      name: "phone",
      className: "col-12",
      required: true,
      placeholder: "Phone",
    },
    {
      type: "text",
      valid: false,
      name: "fname",
      className: "col-6",
      required: true,
      placeholder: "First Name",
    },
    {
      type: "text",
      valid: false,
      name: "lname",
      className: "col-6",
      required: true,
      placeholder: "Last Name",
    },
    {
      type: "text",
      valid: false,
      name: "addr1",
      className: "col-6",
      required: true,
      placeholder: "Address",
    },
    {
      type: "number",
      valid: false,
      name: "age",
      className: "col-6",
      required: true,
      placeholder: "Age",
    },
    {
      type: "text",
      valid: false,
      name: "company",
      className: "col-12",
      required: true,
      placeholder: "Company",
    },
    {
      type: "text",
      valid: false,
      name: "occupation",
      className: "col-12",
      required: true,
      placeholder: "Occupation",
    },
    {
      type: "text",
      valid: false,
      name: "referrer",
      className: "col-12",
      required: true,
      placeholder: "How did you know about us?",
    },
    //Always keep Gender as the last item
    {
      type: "radio",
      valid: false,
      name: "gender",
      className: "col-12",
      required: true,
      placeholder: "Gender",
    },
  ],
  locations_points: [],
  selectedSSIDs: "",
  selectedVouchers: [],
  phoneOffer: "",
  disableAds: false,
  fallbackOffer: false,
  verifyEmail: false,
  verifyPhone: false,
  marketingConsent: false,
  voucherWithPhone: false,
  activeTab: "naming",
  tabsStatus: [
    { name: "naming", desc: "Start", status: "todo", active: true },
    {
      name: "config_app",
      desc: "App Settings",
      status: "disabled",
      active: false,
    },
    {
      name: "branches",
      desc: "Create Branches",
      status: "disabled",
      active: false,
    },
    { name: "design", desc: "Start design", status: "disabled", active: false },
    {
      name: "signupform",
      desc: "Phone Signup",
      status: "disabled",
      active: false,
    },
    {
      name: "design_app",
      desc: "Preview app",
      status: "disabled",
      active: false,
    },
    { name: "offers", desc: "Set offers", status: "disabled", active: false },
    { name: "ssids", desc: "Choose SSIDs", status: "disabled", active: false },
  ],
  categoriesFilter: [],
  itemsFilter: [],
  branches: [],
  branchForm: {
    id: 1,
    name: "",
    address: "",
    selectedAPs: [],
    selectedGroups: [],
    tellersView: "s",
    counters: 1,
    screens: [],
  },
  appDetails: {},
  spotifyToken: null,
  oldThemeValues: {},
  byPassLanding: false,
};

const app_api = {
  cp: "cp",
  jb: "jukebox",
  hl: "heplex",
  dr: "doarak",
  ea: "eventassistant",
  nv: "navigation",
};

const app_tabs = {
  cp: ["naming", "design", "offers", "ssids"],
  dm: ["naming", "config_app", "design", "design_app", "ssids"],
  jb: ["naming", "config_app", "design", "ssids"],
  hl: ["naming", "config_app", "design", "ssids"],
  dr: ["naming", "config_app", "branches", "ssids"],
  ea: ["naming", "design", "ssids"],
  nv: ["naming", "design", "ssids"],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NAME:
      return {
        ...state,
        name: action.payload.name,
        app_api:
          action.payload.template !== "dm"
            ? app_api[action.payload.template]
            : action.payload.app_api,
        template: action.payload.template,
        theme: action.payload.theme || "t1",
        tabsStatus: state.tabsStatus.filter((tab) =>
          app_tabs[action.payload.template].includes(tab.name)
        ),
      };
    case CHANGE_LOGO:
      return {
        ...state,
        logo: action.payload,
      };
    case CHANGE_DM_LOGO:
      return {
        ...state,
        dmLogo: action.payload,
      };
    case CHANGE_DM_LOGINBG:
      return {
        ...state,
        loginBg: action.payload,
      };
    case SET_MENU_FILTER:
      return {
        ...state,
        categoriesFilter: action.categoriesFilter,
        itemsFilter: action.itemsFilter,
      };
    case CHANGE_TITLE:
      return {
        ...state,
        title:
          typeof action.payload === "string"
            ? typeof state.title === "string"
              ? action.payload
              : {
                  ...state.title,
                  en: action.payload,
                }
            : typeof state.title === "string"
            ? {
                en: action.payload.en || state.title,
                ar: action.payload.ar,
              }
            : {
                ...state.title,
                ...action.payload,
              },
      };
    case CHANGE_THEME:
      const currentTheme = state.theme;
      const newTheme = action.payload;
      return {
        ...state,
        theme: action.payload,
        logo: state.logo
          ? state.logo
          : state.oldThemeValues?.[newTheme]
          ? state.oldThemeValues?.[newTheme].logo
          : themeProps[newTheme].logo,
        dynamicBg: state.oldThemeValues?.[newTheme]
          ? state.oldThemeValues?.[newTheme].dynamicBg
          : themeProps[newTheme].dynamicBg,
        oldThemeValues: {
          ...state.oldThemeValues,
          [currentTheme]: {
            logo: state.logo || themeProps[currentTheme].logo,
            dynamicBg: state.dynamicBg || themeProps[currentTheme].dynamicBg,
          },
        },
      };
    case CHANGE_LOGIN_BG:
      return {
        ...state,
        loginBg: action.payload || "",
      };
    case CHANGE_DESC:
      return {
        ...state,
        desc:
          typeof action.payload === "string"
            ? typeof state.desc === "string"
              ? action.payload
              : {
                  ...state.desc,
                  en: action.payload,
                }
            : typeof state.desc === "string"
            ? {
                en: action.payload.en || state.desc,
                ar: action.payload.ar,
              }
            : {
                ...state.desc,
                ...action.payload,
              },
      };
    case CHANGE_POWEREDBY:
      return {
        ...state,
        poweredBy: action.payload,
      };
    case SET_LOCATIONS_POINTS:
      return {
        ...state,
        locations_points: action.payload,
      };
    case ASSIGN_SSIDS:
      return {
        ...state,
        selectedSSIDs: action.payload,
      };
    case ASSIGN_VOUCHERS:
      return {
        ...state,
        selectedVouchers: action.payload.selectedVouchers
          ? action.payload.selectedVouchers
          : action.payload,
      };
    case SET_OFFER:
      return {
        ...state,
        phoneOffer: action.payload,
      };
    case SET_FALL_BACK:
      return {
        ...state,
        fallbackOffer: action.payload,
      };
    case SET_DISABLE_ADS:
      return {
        ...state,
        disableAds: action.payload,
      };
    case SET_ID:
      return {
        ...state,
        id: action.payload,
        digitalMenuToken: action.payload,
      };
    case SET_LOGO:
      return {
        ...state,
        logo: action.payload,
      };
    case SET_TOKEN:
      return {
        ...state,
        digitalMenuToken: action.payload,
        DM_ID: action.ID ? action.ID : "",
        id: action.ID ? action.ID : state.id,
      };
    case CHANGE_FORMDETAILS:
      return {
        ...state,
        formDetails: state.formDetails.map((input) =>
          input.name === action.payload.name
            ? { ...input, [action.payload.prop]: action.payload.val }
            : { ...input }
        ),
      };
    case CHANGE_SIGNUP_METHODS:
      return {
        ...state,
        signupMethods: state.signupMethods.map((method) =>
          method.name === action.payload.name
            ? { ...method, valid: action.payload.val, offer: "" }
            : { ...method }
        ),
      };
    case CHANGE_SIGNUP_METHOD_OFFER:
      return {
        ...state,
        signupMethods: state.signupMethods.map((method) =>
          action.payload.name === "unverified_email" && method.name === "email"
            ? { ...method, unverified_offer: action.payload.val }
            : method.name === action.payload.name
            ? { ...method, offer: action.payload.val }
            : { ...method }
        ),
      };
    case RESET_FORMDETAILS:
      let updatedFormDetails = [...state.formDetails].map((f) => {
        let updatedItem = action.payload.filter((e) => e.name === f.name)[0];
        return updatedItem ? updatedItem : f;
      });
      return {
        ...state,
        formDetails: updatedFormDetails,
      };
    // case RESET_FORMDETAILS:
    //   return {
    //     ...state,
    //     formDetails: action.payload,
    //   };
    case RESET_SIGNUP_METHODS:
      let updatedSignupMethods = [...state.signupMethods].map((f) => {
        let updatedItem = action.payload.filter((e) => e.id === f.id)[0];
        return updatedItem ? updatedItem : f;
      });
      return {
        ...state,
        signupMethods: updatedSignupMethods,
      };
    // case RESET_SIGNUP_METHODS:
    //   return {
    //     ...state,
    //     signupMethods: action.payload,
    //   };
    case CHANGE_BG:
      return {
        ...state,
        dynamicBg: { ...state.dynamicBg, [action.name]: action.val },
      };
    case SET_BG:
      return {
        ...state,
        dynamicBg: { ...state.dynamicBg, ...action.payload },
      };
    case SET_HOST_MAC:
      return {
        ...state,
        hostMac: action.payload,
      };
    case ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.tabName,
        tabsStatus: state.tabsStatus.map((tab) =>
          tab.name === action.tabName
            ? {
                ...tab,
                active: true,
                status: tab.status === "disabled" ? "todo" : tab.status,
              }
            : { ...tab, active: false }
        ),
      };
    case CHANGE_TAB:
      return {
        ...state,
        tabsStatus: state.tabsStatus.map((tab) =>
          tab.name === action.payload.tabName
            ? { ...tab, status: action.payload.status && action.payload.status }
            : { ...tab }
        ),
      };
    case TABS_FOR_EDIT:
      return {
        ...state,
        tabsStatus: state.tabsStatus
          .filter((tab) =>
            (tab.name === "config_app" || tab.name === "design_app") &&
            !action.config_app
              ? false
              : true
          )
          .map((tab) => {
            let status = "done";
            if (tab.name === "signupform")
              status = action.signupform ? "done" : "disabled";
            if (tab.name === "config_app" || tab.name === "design_app")
              status = action.config_app ? "done" : "disabled";
            return { ...tab, status };
          }),
      };

    case SET_QS_SERVICES:
      return {
        ...state,
        logo: action.payload.logo ? action.payload.logo : state.logo,
        appDetails: action.payload,
      };

    case SET_QS_BRANCHES:
      return {
        ...state,
        branches: action.payload,
      };
    case SET_QS_BRANCHFORM:
      console.log(action.payload);
      return {
        ...state,
        branchForm:
          action.payload !== null ? action.payload : initialState.branchForm,
      };

    case REMOVE_QS_BRANCH:
      let newArr = state.branches.filter((b, index) => b.id !== action.payload);
      console.log(action.payload);
      return {
        ...state,
        branches: newArr,
      };
    case EDIT_QS_BRANCH:
      console.log("Hello");
      return {
        ...state,
        branches: state.branches.map((b, index) => {
          if (b.id === action.payload.id) return { ...action.payload };
          return b;
        }),
      };
    case SET_SELECTED_GATEWAY:
      return {
        ...state,
        selectedGateway: action.payload,
      };
    case SET_SPOTIFY_TOKEN:
      return {
        ...state,
        spotifyToken: action.payload,
      };
    case SET_DEFAULT_LANGUAGE:
      return {
        ...state,
        defaultLanguage: action.payload,
      };
    case TOGGLE_NAME_VALUE:
      return {
        ...state,
        [action.name]: action.val,
      };
    case CHANGE_BYPASS_LANDING:
      return {
        ...state,
        byPassLanding: action.payload,
      };
    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
