import axios from "axios";

import {
  POS_SET_USER_INFO,
  POS_TOGGLE_SIDENAV,
  POS_SET_PR_CATEGORIES,
  POS_SET_JP_CATEGORIES,
  POS_SET_LOCATIONS,
  POS_SELECT_CATEGORY,
  POS_SELECT_ITEM,
  POS_ADD_TO_CART,
  POS_DELETE_FROM_CART,
  POS_EMPTY_CART,
  POS_SET_TOKEN,
  POS_SET_APP_COMPONENT,
  POS_SET_ITEMS,
  POS_RESET_STATE,
  POS_FILTER_CATEGORIES,
  POS_SELECT_SUB_CATEGORY,
  POS_SET_FILTER_MODE,
  POS_FILTER_ITEMS,
  // POS_FILTER_JP_CATEGORIES,
  POS_SET_HP_CATEGORIES,
} from "../types";

export const POS_setUser = (user) => ({
  type: POS_SET_USER_INFO,
  payload: user,
});

export const POS_setFilterMode = () => ({ type: POS_SET_FILTER_MODE });

export const POS_reset_state = () => ({ type: POS_RESET_STATE });

export const POS_setToken = (token) => ({
  type: POS_SET_TOKEN,
  payload: token,
});

export const POS_toggleSidenav = () => ({ type: POS_TOGGLE_SIDENAV });

export const POS_selectCategory = (id) => ({
  type: POS_SELECT_CATEGORY,
  payload: id,
});

export const POS_selectItem = (id) => ({ type: POS_SELECT_ITEM, payload: id });

export const POS_deleteFromCart = (itemId) => ({
  type: POS_DELETE_FROM_CART,
  payload: itemId,
});

export const POS_emptyCart = () => ({ type: POS_EMPTY_CART });

export const POS_setAppComponent = (current) => {
  return { type: POS_SET_APP_COMPONENT, payload: current };
};

export const POS_filterCategories = (
  cat_arr = null,
  cat_id = null,
  cat_type = "sub",
  prop_names = ["id", "name"]
) => {
  try {
    console.log("filtered is called .....", cat_arr, cat_id, cat_type);
    return {
      type: POS_FILTER_CATEGORIES,
      cat_arr,
      cat_id,
      cat_type,
      prop_names,
    };
  } catch (err) {
    console.log(err);
  }
};

export const POS_filterItems = (
  items_arr = null,
  item_id = null,
  id_prop = "id"
) => {
  return { type: POS_FILTER_ITEMS, items_arr, item_id, id_prop };
};

export const POS_selectSubCategory = (id) => {
  return { type: POS_SELECT_SUB_CATEGORY, payload: id };
};

export const POS_addToCart = (item, name, price, quantity, rate) => {
  let cart = { id: item.id, name, price, quantity, rate };
  return { type: POS_ADD_TO_CART, payload: { item, cart } };
};

export const POS_update_locations_aps = (locations, accessPoints, cp_id) => {
  return {
    type: POS_SET_LOCATIONS,
    locations,
    accessPoints: cp_id
      ? accessPoints.filter((ap) => ap.cp_id === cp_id || ap.cp_id === null)
      : accessPoints.filter((ap) => ap.cp_id === null),
  };
};

//fetch the data of categories and all (location based) items
function PR_fetchData(location, categoriesFilter, itemsFilter) {
  return async (dispatch) => {
    console.log("working");
    try {
      let [categories, allItems] = await Promise.all([
        axios({
          method: "get",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pr_token")}`,
          },
          url: `${process.env.REACT_APP_API_URL}/posrocketapi/list-categories`,
        }),
        axios({
          method: "post",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pr_token")}`,
          },
          url: `${process.env.REACT_APP_API_URL}/posrocketapi/list-items`,
          data: { location_id: location },
        }),
      ]);
      console.log(categories.data.data);
      await dispatch({
        type: POS_SET_PR_CATEGORIES,
        payload: categories.data.data,
        categoriesFilter,
        allItems: allItems.data.data,
      });
      //dispatch(POS_filterCategories(categoriesFilter, null))
      dispatch({
        type: POS_SELECT_CATEGORY,
        payload: categories.data.data[0].id,
      });
      dispatch({
        type: POS_SET_ITEMS,
        payload: allItems.data.data,
        itemsFilter,
        id_prop: "id",
      });
    } catch (e) {
      console.log(e);
    }
  };
}

function JP_fetchData(location = "l", categoriesFilter, itemsFilter) {
  return async (dispatch) => {
    console.log(".......................................................");
    try {
      let [categories, allItems, taxes, tables] = await Promise.all([
        axios({
          method: "get",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pr_token")}`,
          },
          url: `${process.env.REACT_APP_API_URL}/joinposterapi/list-categories`,
        }),
        axios({
          method: "post",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pr_token")}`,
          },
          url: `${process.env.REACT_APP_API_URL}/joinposterapi/list-items`,
          data: { location_id: location },
        }),
        axios({
          method: "get",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pr_token")}`,
          },
          url: `${process.env.REACT_APP_API_URL}/joinposterapi/gettaxes`,
        }),
        axios({
          method: "get",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pr_token")}`,
          },
          url: `${process.env.REACT_APP_API_URL}/joinposterapi/get-all-tables`,
        }),
      ]);
      console.log({ categories, allItems, taxes, tables });
      dispatch({
        type: POS_SET_JP_CATEGORIES,
        payload: categories.data.data,
        categoriesFilter,
      });
      dispatch(
        POS_filterCategories(categoriesFilter, null, "main", [
          "category_id",
          "category_name",
        ])
      );
      dispatch(
        POS_filterCategories(categoriesFilter, null, "sub", [
          "category_id",
          "category_name",
        ])
      );
      dispatch({
        type: POS_SELECT_CATEGORY,
        payload: categories.data.data[0].category_id,
        categoriesFilter,
      });
      dispatch({
        type: POS_SET_ITEMS,
        payload: allItems.data.data,
        taxes: taxes ? taxes.data.data : [],
        tables: tables ? tables.data.data : [],
        itemsFilter,
        id_prop: "product_id",
      });
    } catch (e) {
      console.log(e);
    }
  };
}

// fetch data from hoopoe
function HP_fetchData(location = "l", categoriesFilter, itemsFilter) {
  return async (dispatch) => {
    try {
      const { data: Menu } = await axios({
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("pr_token")}`,
        },
        url: `${process.env.REACT_APP_API_URL}/hoopoepos/menu`,
        data: { location },
      });
      console.dir(Menu, { depth: null });
      /*dispatch({
          type: SET_HOOPOE_MENU,
          restaurant: Menu.restaurant, 
          branch: Menu.branch, 
          categories: Menu.categories, 
          items: Menu.items,
          categoriesFilter, 
          itemsFilter, 
          id_prop: "id",
          onlinePayment: false,
        });*/
      dispatch({
        type: POS_SET_HP_CATEGORIES,
        payload: Menu.categories,
        categoriesFilter,
      });
      dispatch(
        POS_filterCategories(categoriesFilter, null, "main", ["id", "name"])
      );
      dispatch(
        POS_filterCategories(categoriesFilter, null, "sub", ["id", "name"])
      );
      dispatch({
        type: POS_SELECT_CATEGORY,
        payload: Menu.categories[0].id,
        categoriesFilter,
      });
      dispatch({
        type: POS_SET_ITEMS,
        payload: Menu.items,
        taxes: Menu.restaurant ? Menu.restaurant.props.taxes : [],
        tables: Menu.restaurant ? Menu.restaurant.props.tabs : [],
        itemsFilter,
        id_prop: "id",
      });
    } catch (e) {
      console.log(e);
    }
  };
}

//fetch the data of categories and all (location based) items
export const POS_getAllItems = (
  location = "8fad4821-a24a-4fe5-9af7-5264046e4db3"
) => {
  return (dispatch, getState) => {
    const { app_api, categoriesFilter, itemsFilter } = getState().cpEditor;
    console.log({ app_api, location });
    switch (app_api) {
      case "posrocket":
        return dispatch(PR_fetchData(location, categoriesFilter, itemsFilter));
      case "joinposter":
        return dispatch(JP_fetchData(location, categoriesFilter, itemsFilter));
      case "hoopoe":
        return dispatch(HP_fetchData(location, categoriesFilter, itemsFilter));
      default:
        return null;
    }
  };
};

// get locations and access points to connect them together
export const POS_handleLocations = (cp_id) => {
  return async (dispatch, getState) => {
    try {
      let { app_api } = getState().cpEditor;
      let location_url =
        app_api === "posrocket"
          ? "posrocketapi"
          : app_api === "hoopoe"
          ? "hoopoepos"
          : "joinposterapi";
      let [accessPoints, locations] = await Promise.all([
        axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/network/accesscontrollers/all`,
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }),
        axios({
          method: "get",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pr_token")}`,
          },
          url: `${process.env.REACT_APP_API_URL}/${location_url}/get-locations`,
        }),
      ]);
      console.log({ accessPoints, locations });
      let location =
        app_api === "posrocket" || app_api === "hoopoe"
          ? locations.data.data[0].id
          : "";
      dispatch(POS_getAllItems(location));
      dispatch(
        POS_update_locations_aps(
          locations.data.data,
          accessPoints.data.data,
          cp_id
        )
      );
    } catch (e) {
      console.log(e);
    }
  };
};
