import {
  ANALYTICS_START,
  ANALYTICS_ERROR,
  ANALYTICS_SUCCESS,
  SET_MODULES,
  SET_CHARTS,
  SET_COMPONENT,
  SET_INSTALLED_MODULES,
  SET_INSTALLED_CHARTS,
  SET_MODULE,
  ADD_MODULE,
} from "../types";

const initialState = {
  isLoading: false,
  error: null,
  modules: [],
  charts: [],
  installedModules: [1, 2],
  installedCharts: [],
  module: "",
  currentComponent: "overview",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ANALYTICS_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case ANALYTICS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case ANALYTICS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case SET_MODULES:
      return {
        ...state,
        modules: action.payload,
      };
    case SET_CHARTS:
      return {
        ...state,
        charts: action.payload,
      };
    case ADD_MODULE:
      return {
        ...state,
        installedModules: [...state.installedModules, action.payload],
      };
    case SET_INSTALLED_MODULES:
      return {
        ...state,
        installedModules: action.payload,
      };
    case SET_INSTALLED_CHARTS:
      return {
        ...state,
        installedCharts: action.payload,
      };
    case SET_COMPONENT:
      return {
        ...state,
        currentComponent: action.payload,
      };
    case SET_MODULE:
      return {
        ...state,
        module: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
