import axios from "axios";
import {
  ANALYTIC_START,
  ANALYTIC_MSG,
  GET_MODULES,
  GET_CHARTS,
  ADD_MODULE,
  REMOVE_MODULE,
  SET_FILTER,
  SET_GROUPS,
  SET_APS,
  SET_ANALYTICS_APP_DATA,
} from "../types";
// Actions
function start() {
  return { type: ANALYTIC_START };
}
function analyticMsg(payload) {
  return { type: ANALYTIC_MSG, payload };
}

function setModules(modules) {
  return { type: GET_MODULES, payload: modules };
}
function setCharts(charts) {
  return { type: GET_CHARTS, payload: charts };
}

export function setSelectedGroups(groups) {
  return { type: SET_GROUPS, payload: groups };
}
export function setSelectedAPs(aps) {
  return { type: SET_APS, payload: aps };
}
export function setFilter(filter) {
  return { type: SET_FILTER, payload: filter };
}

export function setAnalyticsAppData(appID) {
  return (dispatch) => {
    if (appID !== "none") {
      axios
        .get(
          `https://wmc.dev.hoopoe.digital/api/cloud/get-json-file/${parseInt(
            appID
          )}`
        )
        .then((result) => {
          console.log(result.data.data);
          if (result.data.data) {
            let appData = {
              branches: result.data.data.branches,
              services: result.data.data.appDetails.services,
            };
            dispatch({ type: SET_ANALYTICS_APP_DATA, payload: appData });
          }
        })
        .catch((err) => console.log(err));
    } else {
      dispatch({ type: SET_ANALYTICS_APP_DATA, payload: {} });
    }
  };
}
export const changeFilter = (filter, selectedGroups) => {
  localStorage.setItem("analytics_filter", JSON.stringify(filter));

  return (dispatch, getState) => {
    dispatch(setFilter(filter));
    const selectedGroups = getState().theAnalytics.selectedGroups;
    const selectedAPs = getState().theAnalytics.selectedAPs;
    localStorage.setItem("selectedGroups", JSON.stringify(selectedGroups));
    localStorage.setItem("selectedAPs", JSON.stringify(selectedAPs));
    // console.log(filter);
    if (filter.appID) {
      dispatch(setAnalyticsAppData(filter.appID));
    }
  };
};

export const addModule = (module) => {
  return { type: ADD_MODULE, payload: module };
};
export const removeModule = (module) => {
  return { type: REMOVE_MODULE, payload: module };
};
export const getInstalledModules = () => {
  return (dispatch) => {
    dispatch(start());
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/analytics/getinstalledmodules`,
    })
      .then((result) => {
        console.log(result.data);
        dispatch(setModules(result.data.fetchedModules));
      })
      .catch((err) => {
        dispatch(analyticMsg({ type: "error", msg: err }));
      });
  };
};

export const getInstalledCharts = () => {
  return (dispatch) => {
    dispatch(start());
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/analytics/getinstalledcharts`,
    })
      .then((result) => {
        // console.log(result.data);
        dispatch(setCharts(result.data.fetchedCharts));
      })
      .catch((err) => {
        dispatch(analyticMsg({ type: "error", msg: err }));
      });
  };
};

export const handleGroups = (e, gr) => {
  return (dispatch, getState) => {
    let groups = getState().theAnalytics.selectedGroups;
    if (e.target.checked === true) {
      groups = [...groups, gr.ID];
      dispatch(setSelectedGroups(groups));
    } else {
      let index = groups.findIndex((x) => x === gr.ID);
      groups.splice(index, 1);
      dispatch(setSelectedGroups(groups));
    }
  };
};

export const handleAPs = (e, ap) => {
  return (dispatch, getState) => {
    let selectedAPs = getState().theAnalytics.selectedAPs;
    if (e.target.checked === true) {
      selectedAPs = [...selectedAPs, ap.id];
      dispatch(setSelectedAPs(selectedAPs));
    } else {
      let index = selectedAPs.findIndex((x) => x === ap.id);
      selectedAPs.splice(index, 1);
      dispatch(setSelectedAPs(selectedAPs));
    }
  };
};
