import {
  DASHBOARD_START,
  DASHBOARD_MSG,
  GET_REGISTERED,
  GET_ONLINE,
  SET_ONLINE_DEVICES,
  GET_ACCESSPOINTS,
  GET_DOWNLOAD,
  GET_UPLOAD,
  SHOW_ONLINE,
  SHOW_REGISTERED,
  SHOW_LOCATIONS,
  SET_GENERATED_SESSIONS,
  SET_REGISTERED_DEVICES,
  SHOW_DEVICE_INFO,
  SET_AVG_SESSION_DURATION,
  SET_AVG_GB_PER_DAY,
  SHOW_ONLINE_DEVICE_INFO,
  SHOW_AP_STATUS,
  SET_SMS_CONSUMPTION,
} from "../types";

const initialState = {
  isLoading: false,
  msg: null,
  registeredUsers: "",
  registeredDevices: "",
  onlineUsers: "",
  onlineDevices: "",
  accessPoints: [],
  uploadQuota: "",
  downloadQuota: "",
  generatedSessions: "",
  avgSessionDuration: "",
  avgGBPerDay: "",
  smsConsumption: "",
  showOnline: false,
  showRegistered: false,
  showLocations: false,
  showDeviceInfo: false,
  showOnlineDeviceInfo: false,
  showAPStatus: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_START:
      return {
        ...state,
        isLoading: true,
        msg: null,
      };
    case DASHBOARD_MSG:
      return {
        ...state,
        isLoading: false,
        msg: action.payload,
      };
    case GET_REGISTERED:
      return {
        ...state,
        registeredUsers: action.payload,
      };
    case SET_REGISTERED_DEVICES:
      return {
        ...state,
        registeredDevices: action.payload,
      };
    case SET_ONLINE_DEVICES:
      return {
        ...state,
        onlineDevices: action.payload,
      };
    case SET_AVG_SESSION_DURATION:
      return {
        ...state,
        avgSessionDuration: action.payload,
      };
    case SET_AVG_GB_PER_DAY:
      return {
        ...state,
        avgGBPerDay: action.payload,
      };
    case SET_SMS_CONSUMPTION:
      return {
        ...state,
        smsConsumption: action.payload,
      };
    case GET_ONLINE:
      return {
        ...state,
        onlineUsers: action.payload,
      };
    case SET_GENERATED_SESSIONS:
      return {
        ...state,
        generatedSessions: action.payload,
      };
    case SHOW_ONLINE:
      return {
        ...state,
        showOnline: !state.showOnline,
        showOnlineDeviceInfo: false,
        showAPStatus: false,
      };
    case SHOW_ONLINE_DEVICE_INFO:
      return {
        ...state,
        showOnlineDeviceInfo: !state.showOnlineDeviceInfo,
        showOnline: false,
        showAPStatus: false,
      };
    case SHOW_AP_STATUS:
      return {
        ...state,
        showAPStatus: !state.showAPStatus,
        showOnlineDeviceInfo: false,
        showOnline: false,
      };
    case SHOW_LOCATIONS:
      return {
        ...state,
        showLocations: !state.showLocations,
        showRegistered: !state.showLocations ? false : state.showRegistered,
        showDeviceInfo: false,
      };
    case SHOW_REGISTERED:
      return {
        ...state,
        showRegistered: !state.showRegistered,
        showLocations: state.showLocations ? false : state.showLocations,
        showDeviceInfo: false,
      };
    case SHOW_DEVICE_INFO:
      return {
        ...state,
        showDeviceInfo: !state.showDeviceInfo,
        showLocations: false,
        showRegistered: false,
      };
    case GET_ACCESSPOINTS:
      return {
        ...state,
        accessPoints: action.payload,
      };
    case GET_DOWNLOAD:
      return {
        ...state,
        downloadQuota: action.payload,
      };
    case GET_UPLOAD:
      return {
        ...state,
        uploadQuota: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
