import axios from "axios";
import {
  ANALYTICS_START,
  ANALYTICS_ERROR,
  ANALYTICS_SUCCESS,
  SET_CHARTS,
  SET_MODULES,
  SET_INSTALLED_CHARTS,
  SET_INSTALLED_MODULES,
  SET_COMPONENT,
  SET_MODULE,
} from "../types";
import { addModule, removeModule } from "./analyticsActions";

// Actions
export const start = () => {
  return { type: ANALYTICS_START };
};

export const finish = () => {
  return { type: ANALYTICS_SUCCESS };
};
export const analyticsError = (err) => {
  return { type: ANALYTICS_ERROR, error: err };
};

export const setModules = (modules) => {
  return { type: SET_MODULES, payload: modules };
};
export const setCharts = (charts) => {
  return { type: SET_CHARTS, payload: charts };
};
export const setInstalledModules = (modules) => {
  return { type: SET_INSTALLED_MODULES, payload: modules };
};
export const setInstalledCharts = (charts) => {
  return { type: SET_INSTALLED_CHARTS, payload: charts };
};
export const setCurrentComponent = (current) => {
  return { type: SET_COMPONENT, payload: current };
};
export const setModule = (module) => {
  return { type: SET_MODULE, payload: module };
};

export const changeComponent = (module) => {
  return (dispatch) => {
    dispatch(setCurrentComponent("module"));
    dispatch(setModule(module));
  };
};

export const getModules = () => {
  return (dispatch) => {
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_HDS_API_URL}/modules`,
    })
      .then((result) => {
        dispatch(setModules(result.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getCharts = () => {
  return (dispatch) => {
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_HDS_API_URL}/charts`,
    })
      .then((result) => {
        console.log(result.data);
        dispatch(setCharts(result.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const installModule = (mod) => {
  return (dispatch) => {
    console.log(mod);
    dispatch(start());
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/modules/installmodule`,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      data: mod,
    })
      .then((result) => {
        if (result.data.message === "success") {
          dispatch(addModule(mod.id));
          dispatch(finish());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const uninstallModule = (mod) => {
  return (dispatch) => {
    console.log("uninstall");
    dispatch(start());
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/modules/uninstallmodule`,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      data: mod,
    })
      .then((result) => {
        if (result.data.message === "success") {
          dispatch(removeModule(mod.id));
          dispatch(finish());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
