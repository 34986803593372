import { toast } from "react-toastify";
import axios from "axios";

import {
  SET_NAME,
  CHANGE_LOGO,
  CHANGE_DM_LOGO,
  CHANGE_DM_LOGINBG,
  CHANGE_LOGIN_BG,
  CHANGE_TITLE,
  CHANGE_THEME,
  CHANGE_DESC,
  CHANGE_FORMDETAILS,
  SET_BG,
  CHANGE_SIGNUP,
  SET_SPOTIFY_TOKEN,
  SET_QS_BRANCHFORM,
  ACTIVE_TAB,
  SET_DISABLE_ADS,
  CHANGE_TAB,
  ASSIGN_SSIDS,
  SET_OFFER,
  EDIT_QS_BRANCH,
  SET_FALL_BACK,
  RESET_FORMDETAILS,
  RESET_STATE,
  SET_ID,
  SET_LOGO,
  CHANGE_BG,
  CHANGE_SIGNUP_METHODS,
  RESET_SIGNUP_METHODS,
  ASSIGN_VOUCHERS,
  CHANGE_POWEREDBY,
  SET_HOST_MAC,
  SET_TOKEN,
  TABS_FOR_EDIT,
  SET_LOCATIONS_POINTS,
  CHANGE_SIGNUP_METHOD_OFFER,
  SET_MENU_FILTER,
  SET_QS_SERVICES,
  REMOVE_QS_BRANCH,
  SET_QS_BRANCHES,
  SET_SELECTED_GATEWAY,
  SET_DEFAULT_LANGUAGE,
  TOGGLE_NAME_VALUE,
  CHANGE_BYPASS_LANDING,
} from "../types";

import {
  setCloudLoading,
  cloudError,
  setCurrentComponent,
} from "./cloudActions";
import { update_locations_aps, PR_reset_state } from "./posRocketAppActions";
import { JP_reset_state } from "./joinPosterAppActions";
import { POS_reset_state } from "./posActions";
import {
  dmPOS_reset_state,
  dmPOS_update_locations_aps,
  setBotConfig,
} from "./digitalMenuPosActions";
import { decodeToken } from "./authActions";

// Actions
export const changeLogo = (logo) => {
  // upload the image
  return (dispatch, getState) => {
    const { name } = getState().cpEditor;
    if (!name) console.error("Error: You must have a name first");
    const authData = new FormData();
    authData.append("logoName", name);
    authData.append("file", logo);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/cloud/captiveportals/uploadlogo`,
        authData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((result) => {
        return dispatch({
          type: CHANGE_LOGO,
          payload: `${process.env.REACT_APP_STATIC_URL}/cps-logos/${result.data.fileName}`,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const resetState = () => {
  return { type: RESET_STATE };
};

export const setID = (id) => {
  return { type: SET_ID, payload: id };
};

export const setLogo = (url) => {
  return { type: SET_LOGO, payload: url };
};

export const changeName = (formData) => {
  return { type: SET_NAME, payload: formData };
};

export const changeTitle = (title) => {
  return { type: CHANGE_TITLE, payload: title };
};

export const changeTheme = (theme) => {
  return { type: CHANGE_THEME, payload: theme };
};

export const handleLoginBg = (bg) => {
  return { type: CHANGE_LOGIN_BG, payload: bg };
};

export const setOffers = (offer) => {
  return { type: SET_OFFER, payload: offer };
};

export const setFallBackOffer = (offer) => {
  return { type: SET_FALL_BACK, payload: offer };
};
export const setDisableAds = (e) => {
  return { type: SET_DISABLE_ADS, payload: e };
};

export const changeDesc = (desc) => {
  return { type: CHANGE_DESC, payload: desc };
};

export const changePoweredBy = (poweredBy) => {
  return { type: CHANGE_POWEREDBY, payload: poweredBy };
};

export const setSignupMethod = async (id, val) => {
  return { type: CHANGE_SIGNUP, payload: { id, val } };
};

export const assignSSIDs = (selectedSSIDs) => {
  return { type: ASSIGN_SSIDS, payload: selectedSSIDs };
};

export const setFormDetails = (formDetails) => {
  return { type: RESET_FORMDETAILS, payload: formDetails };
};

function setSignupMethods(signupMethods) {
  return { type: RESET_SIGNUP_METHODS, payload: signupMethods };
}

export const setSelectedGateway = (gateway) => {
  return { type: SET_SELECTED_GATEWAY, payload: gateway };
};

export const setDefaultLanguage = (lang) => {
  return { type: SET_DEFAULT_LANGUAGE, payload: lang };
};

export const changeDynamicBg = (name, val) => {
  return { type: CHANGE_BG, name, val };
};
export const toggleNameValue = (name, val) => {
  return { type: TOGGLE_NAME_VALUE, name, val };
};

export const setDigitalMenuToken = (token) => {
  return async (dispatch) => {
    const payload = await decodeToken(token);
    if (!payload)
      return dispatch(cloudError("not a valid token, please try again"));
    const { result, customerId, access_token, ID } = payload;
    if (result && customerId === +localStorage.getItem("userId")) {
      localStorage.setItem("pr_token", token);
      console.log("New Digital menu ID: ", ID);
      dispatch({ type: SET_TOKEN, payload: access_token, ID });
    } else {
      dispatch(cloudError("not a valid token, please try again"));
    }
  };
};

export const setJoinPosterToken = (token) => {
  return async (dispatch) => {
    try {
      const payload = await decodeToken(token);
      if (!payload) throw new Error("not a valid token, please try again");
      const { access_token } = payload;
      localStorage.setItem("pr_token", token);
      dispatch({ type: SET_TOKEN, payload: access_token });
    } catch (err) {
      console.log(err);
    }
  };
};

export const setSimpleTouchToken = (token) => {
  return (dispatch) => {
    try {
      localStorage.setItem("pr_token", token);
      dispatch({ type: SET_TOKEN, payload: token });
    } catch (err) {
      console.log(err);
    }
  };
};

function enableTabsForEdit(signupform, config_app) {
  console.log(signupform, config_app);
  return { type: TABS_FOR_EDIT, signupform, config_app };
}

function setDynamicBg(values) {
  return { type: SET_BG, payload: values };
}

function assignVouchers(values) {
  console.log("voucher groups: ", values);
  return { type: ASSIGN_VOUCHERS, payload: values };
}

export function setBranchForm(form) {
  return { type: SET_QS_BRANCHFORM, payload: form };
}

export function setHostMac(mac) {
  return { type: SET_HOST_MAC, payload: mac };
}

export function changeBranchForm(e) {
  return (dispatch, getState) => {
    let branchForm = getState().cpEditor.branchForm;
    dispatch(
      setBranchForm({
        ...branchForm,
        [e.target.name]: e.target.value,
      })
    );
  };
}

// handle change in counters and screens in the Branch form for Doarak App configuration
export function changeBranchCounters(counters, screens) {
  return (dispatch, getState) => {
    let branchForm = getState().cpEditor.branchForm;
    dispatch(
      setBranchForm({
        ...branchForm,
        counters: counters,
        screens: screens,
      })
    );
  };
}

export function changeBranchCoords(coords) {
  return (dispatch, getState) => {
    let branchForm = getState().cpEditor.branchForm;
    console.log(branchForm);
    console.log({ coords });
    branchForm.lat = coords.lat;
    branchForm.lng = coords.lng;
    dispatch(setBranchForm(branchForm));
  };
}

function setName(page, formData) {
  console.log(formData);
  return (dispatch, getState) => {
    // check if the name value equal the state value (when editing)
    if (formData.name === getState().cpEditor.name) {
      dispatch(changeName(formData));
      dispatch(changeTabsStatus(page, "done"));
      dispatch(
        changeTab(
          formData.template === "cp" ||
            formData.template === "ea" ||
            formData.template === "nv"
            ? "design"
            : "config_app"
        )
      );
    } else {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/cloud/captiveportals/check`,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        data: { name: formData.name },
      })
        .then((result) => {
          if (result.data.status === "success") {
            dispatch(changeName(formData));
            dispatch(changeTabsStatus(page, "done"));
            dispatch(
              changeTab(
                formData.template === "cp" ||
                  formData.template === "ea" ||
                  formData.template === "nv"
                  ? "design"
                  : "config_app"
              )
            );
          } else {
            dispatch(cloudError(result.data.message));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
}

function setApp(page) {
  return (dispatch) => {
    dispatch(changeTabsStatus(page, "done"));
    dispatch(changeTab("design"));
  };
}

function finishDesign(page, formData, currentTab) {
  console.log(currentTab);
  console.log(formData);
  return (dispatch, getState) => {
    const tabsStatus = getState().cpEditor.tabsStatus;
    const currentIndex = tabsStatus.findIndex((t) => t.name === currentTab);
    const nextTab = tabsStatus[currentIndex + 1].name;
    console.log({ tabsStatus, currentIndex, nextTab });
    dispatch(assignVouchers(formData));
    dispatch(changeTabsStatus(page, "done"));
    dispatch(changeTab(nextTab));
  };
}

function selectOffer(page, offerName) {
  return (dispatch) => {
    dispatch(changeTabsStatus(page, "done"));
    dispatch(setOffers(offerName));
    dispatch(changeTab("ssids"));
  };
}

function setForm(page, nextTab) {
  return (dispatch) => {
    dispatch(changeTabsStatus(page, "done"));
    dispatch(changeTab(nextTab));
  };
}

export function setServices(services) {
  return (dispatch) => {
    dispatch({ type: SET_QS_SERVICES, payload: services });
  };
}

export function setSpotifyToken(token) {
  return (dispatch) => {
    dispatch({ type: SET_SPOTIFY_TOKEN, payload: token });
  };
}

function setAppDetails(appDetails) {
  return (dispatch) => {
    dispatch({ type: SET_QS_SERVICES, payload: appDetails });
  };
}

export function setQSAppDesign(page, appDetails) {
  return (dispatch) => {
    dispatch({ type: SET_QS_SERVICES, payload: appDetails });
    dispatch(changeTabsStatus(page, "done"));
    dispatch(changeTab("branches"));
  };
}

export const handleQSGroups = (e, gr, i) => {
  return (dispatch, getState) => {
    let branchForm = getState().cpEditor.branchForm;
    let APs = branchForm.selectedAPs;
    let groups = branchForm.selectedGroups;
    let aps = getState().cloud.aps;
    let apMacs = [];
    gr.APs.split(",").map((ap) =>
      aps.map((a) => {
        // eslint-disable-next-line eqeqeq
        if (a.id == ap) {
          apMacs.push(a.community);
        }
        return a;
      })
    );
    if (e.target.checked === true) {
      APs = [...APs, ...apMacs];
      groups = [...groups, gr.ID];
      branchForm.selectedAPs = APs;
      branchForm.selectedGroups = groups;
      dispatch(setBranchForm(branchForm));
    } else {
      apMacs.map((ap) => {
        let index = APs.findIndex((x) => x === ap.community);
        APs.splice(index, 1);
        return ap;
      });
      console.log(APs);
      let groupIndex = groups.findIndex((x) => x === gr.id);
      groups.splice(groupIndex, 1);
      branchForm.selectedAPs = APs;
      branchForm.selectedGroups = groups;
      dispatch(setBranchForm(branchForm));
    }
  };
};

export const saveBranches = () => {
  return (dispatch) => {
    dispatch(changeTabsStatus("branches", "done"));
    dispatch(changeTab("ssids"));
  };
};

export const handleQSAPs = (e, ap, i) => {
  return (dispatch, getState) => {
    let branchForm = getState().cpEditor.branchForm;
    console.log(branchForm);
    let APs = branchForm.selectedAPs;
    if (e.target.checked === true) {
      APs = [...APs, ap.community];
      console.log(APs);
      branchForm.selectedAPs = APs;
      dispatch(setBranchForm(branchForm));
    } else {
      let index = APs.findIndex((x) => x === ap.community);
      APs.splice(index, 1);
      branchForm.selectedAPs = APs;
      dispatch(setBranchForm(branchForm));
    }
  };
};

function setAppDesign(page, formData) {
  return (dispatch) => {
    dispatch({ type: SET_LOCATIONS_POINTS, payload: formData });
    dispatch(changeTabsStatus(page, "done"));
    dispatch(changeTab("ssids"));
  };
}

function setSSIDs(page, formData) {
  return async (dispatch, getState) => {
    console.log(formData);
    dispatch(setCloudLoading(true));
    dispatch(changeTabsStatus(page, "done"));
    dispatch(assignSSIDs(formData));
    let {
      id,
      name,
      template,
      hostMac,
      theme,
      logo,
      dmLogo,
      loginBg,
      title,
      desc,
      poweredBy,
      formDetails,
      signupMethods,
      phoneOffer,
      selectedSSIDs,
      selectedVouchers,
      dynamicBg,
      digitalMenuToken,
      locations_points,
      DM_ID,
      app_api,
      disableAds,
      verifyEmail,
      verifyPhone,
      marketingConsent,
      voucherWithPhone,
      fallbackOffer,
      services,
      branches,
      counters,
      selectedGateway,
      defaultLanguage,
      tellersView,
      appDetails,
      spotifyToken,
      byPassLanding,
    } = getState().cpEditor;
    let { currentComponent } = getState().cloud;
    let { filteredCategories, filteredItems, whatsAppBotEnabling, botConfig } =
      getState().digitalMenuPos;
    let { categories, items, subCategories } = getState().pos;
    // prepare for edit
    if (!logo)
      logo = `${process.env.REACT_APP_STATIC_URL}/cps-logos/default.png`;
    if (!title) title = "Hoopoe Digital";
    if (!desc) desc = "Enjoy our Free Wifi and Digital Service";
    if (!poweredBy) poweredBy = "Hoopoe Digital LLC";
    let dm_token = template === "dm" ? digitalMenuToken : "";
    let categoriesFilter = [
      ...categories?.reduce((a, o) => o.checked && a.push(o.id), []),
      ...subCategories?.reduce((a, o) => o.checked && a.push(o.id), []),
    ];
    let itemId = app_api === "posrocket" ? "id" : "product_id";
    let itemsFilter = items?.reduce(
      (a, o) => o.checked && a.push(o[itemId]),
      []
    );
    if (!categoriesFilter.length || !itemsFilter.length) {
      categoriesFilter = filteredCategories;
      itemsFilter = filteredItems;
    }
    // when creating new captive portal
    let data = {
      id,
      name,
      template,
      app_api,
      // theme: template !== "dm" ? "t1" : theme,
      theme: theme || "t1",
      hostMac: template === "hl" ? hostMac.split("-").join(":") : null,
      logo,
      dmLogo,
      loginBg,
      title,
      desc,
      poweredBy,
      formDetails,
      signupMethods,
      ssids: selectedSSIDs,
      phoneOffer,
      dynamicBg,
      selectedGroups: selectedVouchers.join(","),
      access_token: dm_token,
      locations_points,
      DM_ID,
      fallbackOffer,
      disableAds,
      verifyEmail,
      verifyPhone,
      marketingConsent,
      voucherWithPhone,
      categoriesFilter,
      itemsFilter,
      services,
      branches,
      counters,
      selectedGateway,
      defaultLanguage,
      tellersView,
      appDetails,
      spotifyToken,
      botConfig,
      whatsAppBotEnabling,
      byPassLanding,
    };

    console.log(data);

    // check dynamicBg.bgImage.newFiles for new files and upload them, then update dynamicBg with the new URLs and delete the newFiles object
    if (dynamicBg?.bgImage?.newFiles) {
      const sizes = Object.keys(dynamicBg.bgImage.newFiles);

      const tablesPrefix = localStorage.getItem("tablesPrefix");

      const TYPE_MAP = {
        "image/png": "png",
        "image/jpeg": "jpg",
        "image/gif": "gif",
        "image/svg+xml": "svg",
      };

      const generateFileName = (size, file) => {
        return `${tablesPrefix}-cp${id}-${size}.${TYPE_MAP[file.type]}`;
      };

      for (let i = 0; i < sizes.length; i++) {
        const size = sizes[i];
        const file = dynamicBg.bgImage.newFiles[size];
        const fileName = generateFileName(
          size,
          file
        );
        if (!file || !fileName) {
          console.warn("no file or fileName", size, file, fileName);
          continue;
        }
        const formData = new FormData();
        formData.append("file", file, fileName);
        try {
          const result = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}/images/upload`,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            data: formData,
          });
          if (result.status === 200) {
            console.log("success, uploaded: ", result.data);
            dynamicBg.bgImage[size] = result.data.url;
          } else {
            console.log("error", result);
          }
        } catch (e) {
          console.log(e);
        }
      }

      delete dynamicBg.bgImage.newFiles;
    }

    if (currentComponent === "new") {
      console.log({ data });

      const formData = new FormData();
      // check if I need to upload menu images for whatsApp
      if (template === "dm" && app_api === "hoopoe") {
        const { ar, en } = data.botConfig.images.Main;
        if (ar.file) {
          formData.append("attachment", ar.file, "ar");
          data.botConfig.images.Main.ar.link = "";
        }
        if (en.file) {
          formData.append("attachment", en.file, "en");
          data.botConfig.images.Main.en.link = "";
        }
      }
      formData.append("data", JSON.stringify(data));

      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/cloud/captiveportals/add`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          contentType: "multipart/form-data",
        },
        data: formData,
      })
        .then((result) => {
          if (result.data.status === "success") {
            console.log("success");
            dispatch(resetState());
            dispatch(JP_reset_state());
            dispatch(POS_reset_state());
            dispatch(dmPOS_reset_state());
            dispatch(setCurrentComponent("overview"));
            toast.success("✔ Created Successfully");
          } else {
            dispatch(cloudError(result.data.message));
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(setCloudLoading(false));
        });
      // when editing an existing captive portal
    } else {
      const formData = new FormData();
      // check if I need to upload menu images for whatsApp
      if (template === "dm" && app_api === "hoopoe") {
        const { ar, en } = data.botConfig.images.Main;
        if (ar.file) {
          formData.append("attachment", ar.file, "ar");
          data.botConfig.images.Main.ar.link = "";
        }
        if (en.file) {
          formData.append("attachment", en.file, "en");
          data.botConfig.images.Main.en.link = "";
        }
      }
      data.selectedGateway = data?.appDetails?.selectedGateWay || data?.selectedGateway;
      formData.append("data", JSON.stringify(data));
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/cloud/captiveportals/edit`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          contentType: "multipart/form-data",
        },
        data: formData,
      })
        .then((result) => {
          console.log(result.data);
          if (result.data.status === "success") {
            console.log("success");
            dispatch(resetState());
            dispatch(setCurrentComponent("overview"));
            toast.success("✔ Updated Successfully");
          } else {
            dispatch(cloudError(result.data.message));
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(setCloudLoading(false));
        });
    }
  };
}

export const savePage = (page, formData, nextTab = "") => {
  console.log("saving page");
  console.log(formData);
  switch (page) {
    case "naming":
      return setName(page, formData);
    case "config_app":
      return setApp(page);
    case "design":
      return finishDesign(page, formData, "design");
    case "signupform":
      return setForm(page, nextTab);
    case "design_app":
      return setAppDesign(page, formData);
    case "offers":
      return selectOffer(page, formData);
    case "ssids":
      return setSSIDs(page, formData);
    default:
      return false;
  }
};

export const editCP = (cp) => {
  return async (dispatch) => {
    console.log(cp);
    dispatch(JP_reset_state());
    dispatch(POS_reset_state());
    dispatch(dmPOS_reset_state());
    dispatch(PR_reset_state());
    try {
      let [result, locations_points] = await Promise.all([
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/cloud/prepare-for-edit`,
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          data: {
            cp_id: cp.ID,
            access_token: cp.access_token,
          },
        }),
        axios({
          method: "post",
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          url: `${process.env.REACT_APP_API_URL}/cloud/get-cp-locations`,
          data: { cp_id: cp.ID },
        }),
      ]);
      console.log(result.data);

      // set the name, template theme from the database
      dispatch({
        type: SET_NAME,
        payload: {
          name: cp.Name,
          template: cp.template,
          theme: cp.theme,
          app_api: cp.app_api,
        },
      });

      // set data from captive portal table passed with the function
      dispatch(setID(cp.ID));
      dispatch(assignSSIDs(cp.SSIDs));
      dispatch(setOffers(cp.PhoneOffer));
      dispatch(setFallBackOffer(cp.FallBackOffer));
      dispatch({ type: SET_TOKEN, payload: cp.access_token });
      dispatch(setLogo(cp.Configurations));
      if (result.data.data.template === "dr") {
        console.log(result.data.data);
        dispatch(setAppDetails(result.data.data.appDetails || {}));
        //dispatch(setServices(result.data.data.services));
        dispatch(setBranches(result.data.data.branches));
      }
      // set data from the config file of the captive portal
      if (result.data.data.selectedGroups)
        dispatch(assignVouchers(result.data.data.selectedGroups.split(",")));
      dispatch(changeTitle(result.data.data.title));
      dispatch({
        type: CHANGE_DM_LOGO,
        payload: result.data.data.dmLogo ? result.data.data.dmLogo : "",
      });
      dispatch({
        type: SET_MENU_FILTER,
        categoriesFilter: result.data.data.categoriesFilter
          ? result.data.data.categoriesFilter
          : [],
        itemsFilter: result.data.data.itemsFilter
          ? result.data.data.itemsFilter
          : [],
      });
      if ((result.data.data.theme = "t4"))
        dispatch(handleLoginBg(result.data.data.loginBg));
      dispatch(changeDesc(result.data.data.desc));
      dispatch(changePoweredBy(result.data.data.poweredBy));
      dispatch(changeByPassLanding(result.data.data.byPassLanding || false));
      dispatch(setFormDetails(result.data.data.formDetails));
      dispatch(setDisableAds(result.data.data.disableAds));
      if (result.data.data.verifyEmail)
        dispatch(toggleNameValue("verifyEmail", result.data.data.verifyEmail));
      if (result.data.data.verifyPhone)
        dispatch(toggleNameValue("verifyPhone", result.data.data.verifyPhone));
      if (result.data.data.voucherWithPhone)
        dispatch(
          toggleNameValue("voucherWithPhone", result.data.data.voucherWithPhone)
        );
      if (result.data.data.marketingConsent)
        dispatch(
          toggleNameValue("marketingConsent", result.data.data.marketingConsent)
        );
      dispatch(setDynamicBg(result.data.data.dynamicBg));
      dispatch(setSignupMethods(result.data.data.signupMethods));
      result.data.data.selectedGateway &&
        dispatch(setSelectedGateway(result.data.data.selectedGateway));
      result.data.data.defaultLanguage &&
        dispatch(setDefaultLanguage(result.data.data.defaultLanguage));

      result.data.data.template === "jb" || result.data.data.template === "hl"
        ? dispatch(setHostMac(cp.hostMac))
        : dispatch(setHostMac(""));
      dispatch(
        enableTabsForEdit(
          result.data.data.signupMethods.find((sum) => sum.name === "phone")
            .valid,
          result.data.data.template !== "cp"
        )
      );

      // set the location_points of posrocket app. (convert the ids:"1,2,3" to ids:[1,2,3])
      dispatch({
        type: SET_LOCATIONS_POINTS,
        payload: locations_points.data.data.map((l) => ({
          ...l,
          ids: l.ids.split(",").map(Number),
        })),
      });
      dispatch(update_locations_aps([], []));
      dispatch(
        setBotConfig({
          botConfig: result.data.data.botConfig,
          whatsAppBotEnabling: result.data.data.whatsAppBotEnabling,
        })
      );

      dispatch(dmPOS_update_locations_aps([], []));
      if (result.data.data.template === "jb")
        dispatch(setSpotifyToken(result.data.data.spotifyToken));
      // set the token of posrocket application
      localStorage.setItem("pr_token", result.data.token);
    } catch (e) {
      console.log(e);
    }
  };
};

export const changeFormDetails = (name, val, prop = "valid") => {
  return { type: CHANGE_FORMDETAILS, payload: { name, val, prop } };
};

export const changeSignupMethods = (name, val) => {
  return { type: CHANGE_SIGNUP_METHODS, payload: { name, val } };
};

export const changeMethodOffer = (name, val) => {
  return { type: CHANGE_SIGNUP_METHOD_OFFER, payload: { name, val } };
};

// change the active and status properties of the next tab (tabName)
export const changeTab = (tabName) => {
  return { type: ACTIVE_TAB, tabName };
};

export const removeBranch = (index) => {
  return { type: REMOVE_QS_BRANCH, payload: index };
};

export const setBranches = (branches) => {
  return { type: SET_QS_BRANCHES, payload: branches };
};

export const editBranch = (branch) => {
  return { type: EDIT_QS_BRANCH, payload: branch };
};

export const addBranch = () => {
  return (dispatch, getState) => {
    let branches = getState().cpEditor.branches;
    let id = branches.length + 1;
    dispatch(
      setBranches([
        ...branches,
        { id: id, name: "", selectedAPs: [], selectedGroups: [] },
      ])
    );
  };
};

export const changeTabsStatus = (tabName, status) => {
  return { type: CHANGE_TAB, payload: { tabName, status } };
};

// upload a logo for dm theme 3
export const uploadDmLogo = (logo) => {
  // upload the image
  return (dispatch, getState) => {
    let { name } = getState().cpEditor;
    if (!name) name = "test";
    const authData = new FormData();
    authData.append("logoName", `dm-t3-${name}`);
    authData.append("file", logo);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/cloud/captiveportals/uploadlogo`,
        authData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((result) => {
        return dispatch({
          type: CHANGE_DM_LOGO,
          payload: `${process.env.REACT_APP_STATIC_URL}/cps-logos/${result.data.fileName}`,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const uploadDMBG = (logo) => {
  // upload the image
  return (dispatch, getState) => {
    console.log("Hi from actionssss");
    let { name } = getState().cpEditor;
    if (!name) name = "test";
    const authData = new FormData();
    authData.append("logoName", `dm-t4bg-${name}`);
    authData.append("file", logo);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/cloud/captiveportals/uploadlogo`,
        authData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((result) => {
        console.log(result);
        return dispatch({
          type: CHANGE_DM_LOGINBG,
          payload: `${process.env.REACT_APP_STATIC_URL}/cps-logos/${result.data.fileName}`,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const changeByPassLanding = (val) => {
  return { type: CHANGE_BYPASS_LANDING, payload: val };
};
