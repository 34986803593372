import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./instrumentation";
import store from "./store";
import App from "./App/index";
import config from "./config";

if (process.env.NODE_ENV !== "development") console.log = () => { };

const app = (
  <Provider store={store}>
    <BrowserRouter basename={config.basename} >
      {/* basename="/datta-able" */}
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
