import { DOCS_SET_MODULES } from "../types";

const initialState = {
  modules: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DOCS_SET_MODULES:
      return { ...state, modules: action.payload };
    default:
      return state;
  }
};

export default reducer;
