import axios from "axios";

import {
  POS_SET_FILTER_MODE,
  POS_RESET_STATE,
  POS_SET_TOKEN,
  POS_SET_LOCATIONS,
  SAVE_FILTERED_DATA,
  UPDATE_FILTERED_CATEGORIES,
  UPDATE_FILTERED_ITEMS,
  SET_BOT_CONFIG,
} from "../types";

export const dmPOS_setFilterMode = () => ({ type: POS_SET_FILTER_MODE });

export const dmPOS_reset_state = () => ({ type: POS_RESET_STATE });

export const dmPOS_setToken = (token) => ({
  type: POS_SET_TOKEN,
  payload: token,
});

export const dmPOS_update_locations_aps = (locations, accessPoints, cp_id) => {
  return {
    type: POS_SET_LOCATIONS,
    locations,
    accessPoints: cp_id
      ? accessPoints.filter((ap) => ap.cp_id === cp_id || ap.cp_id === null)
      : accessPoints.filter((ap) => ap.cp_id === null),
  };
};

// get locations and access points to connect them together
const location_url = {
  posrocket: "posrocketapi/get-locations",
  hoopoe: "hoopoepos/get-locations",
  foodics: "digitalmenu/foodics/get-locations",
  joinposter: "joinposterapi/get-locations",
  simphony: "simphony/get-locations",
  simpleTouch: "simple-touch/get-locations",
};

export const dmPOS_handleLocations = (cp_id) => {
  return async (dispatch, getState) => {
    try {
      let { app_api } = getState().cpEditor;

      let [accessPoints, locations] = await Promise.all([
        axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/network/accesscontrollers/all`,
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }),
        axios({
          method: "get",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pr_token")}`,
          },
          url: `${process.env.REACT_APP_API_URL}/${location_url[app_api]}`,
        }),
      ]);
      console.log({ accessPoints, locations });
      dispatch(
        dmPOS_update_locations_aps(
          locations.data.data,
          accessPoints.data.data,
          cp_id
        )
      );
    } catch (e) {
      console.log(e);
    }
  };
};

export const saveFilteredData = (payload) => {
  console.log("saveFilteredData", payload);
  return (dispatch) => {
    dispatch({ type: SAVE_FILTERED_DATA, payload });
  };
};

export const updateFilteredData = (type, id) => {
  console.log("updateFilteredData..........", { id, type });
  if (type === "category")
    return (dispatch) => {
      dispatch({ type: UPDATE_FILTERED_CATEGORIES, id });
    };
  if (type === "item")
    return (dispatch) => {
      dispatch({ type: UPDATE_FILTERED_ITEMS, id });
    };
};

export const setBotConfig = ({ botConfig, whatsAppBotEnabling }) => {
  return (dispatch) => {
    dispatch({
      type: SET_BOT_CONFIG,
      payload: { botConfig, whatsAppBotEnabling },
    });
  };
};
