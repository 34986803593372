import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

// const API_URL = "https://wmcbackend.interns.hoopoe.digital";
const API_URL = "http://localhost:5000";
const API_ENDPOINTS = {
  singleReport: `${API_URL}/api/ganalytics/single`, //POST with body={streamId, filter}
  batchReports: `${API_URL}/api/ganalytics/batch`, //POST with body={streamId, filters}
  apps: `${API_URL}/api/ganalytics/datastreams`, //GET
  generatePermissionsRequestUrl: `${API_URL}/auth/generate_permissions_request_url`,
  checkPermissionsGrant: `${API_URL}/api`,
  revokePermissions: `${API_URL}/auth/revoke`,
};

// TEMP!
const JWT =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxfQ.7Fn7MsfJtAOeDtk9MlUGRwQtDySWcuKO1gnGYLopvAg";

const _fetchDataWithCaching = async (url, body = {}, method = "POST") => {
  let key = JSON.stringify([url, body, method]);
  let data = JSON.parse(window.localStorage.getItem(key))?.data;
  if (!data) {
    console.log("No cache, fetching...");
    let options = {
      method: method,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    if (method === "POST") {
      options.headers["Content-Type"] = "application/json";
      options.body = JSON.stringify(body);
    }
    let res = await fetch(url, options);
    data = await res.json();
    if (res.ok)
      window.localStorage.setItem(
        key,
        JSON.stringify({ data: data, exp: Date.now() + 15 * 60 * 1000 })
      );
  }

  return data;
};

// returns raw response
const _fetchData = async (url, body = {}, method = "POST") => {
  let options = {
    method: method,
    headers: {
      Authorization: `Bearer ${JWT}`,
    },
  };
  if (method === "POST") {
    options.headers["Content-Type"] = "application/json";
    options.body = JSON.stringify(body);
  }

  return await fetch(url, options);
};

export const applyFilters = createAsyncThunk(
  "google/applyFilters",
  async (arg, thunkAPI) => {
    let filters = collectSelectedFilters(
      thunkAPI.getState().theAnalytics.filter
    );
    thunkAPI.dispatch(googleSlice.actions.setLoading(true));
    let dispatch = async (type, dimensionName, metricName) => {
      thunkAPI.dispatch(
        googleSlice.actions.setData({
          type: type,
          value: await _fetchDataWithCaching(API_ENDPOINTS.singleReport, {
            streamId: "2194425887",
            filter: {
              dateRanges: filters.dateRanges,
              dimensions: [
                {
                  name: dimensionName,
                },
              ],
              metrics: [
                {
                  name: metricName,
                },
              ],
            },
          }),
        })
      );
    };

    // Device Info
    await dispatch("deviceCategory", "deviceCategory", "activeUsers");
    await dispatch("deviceModel", "deviceModel", "activeUsers");
    await dispatch("platform", "platform", "activeUsers");
    await dispatch(
      "platformDeviceCategory",
      "platformDeviceCategory",
      "activeUsers"
    );
    await dispatch("browser", "browser", "activeUsers");
    await dispatch("language", "language", "activeUsers");
    await dispatch("operatingSystem", "operatingSystem", "activeUsers");
    await dispatch(
      "operatingSystemWithVersion",
      "operatingSystemWithVersion",
      "activeUsers"
    );

    // User Info
    await dispatch("userAgeBracket", "userAgeBracket", "activeUsers");
    await dispatch("userGender", "userGender", "activeUsers");
    await dispatch("newVsReturning", "newVsReturning", "activeUsers");
    await dispatch("city", "city", "activeUsers");
    await dispatch("country", "country", "activeUsers");
    await dispatch("region", "region", "activeUsers");

    // Session Info
    await dispatch("sessionsPerDay", "day", "sessions");
    await dispatch(
      "averageSessionLengthPerDay",
      "day",
      "averageSessionDuration"
    );

    thunkAPI.dispatch(googleSlice.actions.setLoading(false));
  }
);

export const generatePermissionsRequestUrl = createAsyncThunk(
  "google/generatePermissionsRequestUrl",
  async (arg, thunkAPI) => {
    console.log("Generating url...");

    thunkAPI.dispatch(googleSlice.actions.setLoading(true));

    let data = await (
      await _fetchData(API_ENDPOINTS.generatePermissionsRequestUrl, {}, "GET")
    ).json();

    thunkAPI.dispatch(googleSlice.actions.setLoading(false));
    return data.url;
  }
);

export const checkPermissionsGrant = createAsyncThunk(
  "google/checkPermissionsGrant",
  async (arg, thunkAPI) => {
    thunkAPI.dispatch(googleSlice.actions.setLoading(true));
    let res =
      (await _fetchData(API_ENDPOINTS.checkPermissionsGrant, {}, "GET"))
        .status === 200;
    thunkAPI.dispatch(googleSlice.actions.setLoading(false));
    return res;
  }
);

export const revokePermissions = createAsyncThunk(
  "google/revokePermissions",
  async (arg, thunkAPI) => {
    thunkAPI.dispatch(googleSlice.actions.setLoading(true));
    let res =
      (await _fetchData(API_ENDPOINTS.revokePermissions, {}, "DELETE"))
        .status === 200;
    thunkAPI.dispatch(googleSlice.actions.setLoading(false));
    return res;
  }
);

export const setApps = createAsyncThunk(
  "google/setApps",
  async (arg, thunkAPI) => {
    thunkAPI.dispatch(googleSlice.actions.setLoading(true));
    let res = await getApps(thunkAPI.getState().google.filters);
    thunkAPI.dispatch(googleSlice.actions.setLoading(false));
    return res;
  }
);

export const googleSlice = createSlice({
  name: "google",
  initialState: getInitialState(),
  reducers: {
    // public reducers (exported)
    setFilters: (state, { payload }) => {
      // state.filters[payload.filter] = payload.value;
      console.log(payload);
    },
    setLoading: (state, { payload }) => {
      state.status.isLoading = payload;
    },
    resetFilters: (state) => {
      // reset state.data to original data, status to all false, and filters to initial state
      // state.filters = getInitialState().filters;
    },

    setData: (state, { payload }) => {
      let validatedData = payload.value;
      if (!validatedData) validatedData = [];
      state.data[payload.type] = validatedData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(applyFilters.rejected, (state, { error }) => {
      console.log("Fetch failed");
      state.status.isLoading = false;
      state.status.isError = true;
      console.log(error);

      // TODO: clear cached response if invalid response http code
    });

    builder.addCase(
      generatePermissionsRequestUrl.fulfilled,
      (state, { payload }) => {
        console.log(payload);
        state.permissionsRequestUrl = payload;
      }
    );
    builder.addCase(
      generatePermissionsRequestUrl.rejected,
      (state, { error }) => {
        console.log("Couldn't get the permissions request url...", error);
      }
    );

    builder.addCase(checkPermissionsGrant.fulfilled, (state, { payload }) => {
      state.permissionsGranted = payload;
    });
    builder.addCase(checkPermissionsGrant.rejected, (state, { error }) => {
      console.log("Couldn't check permissions grant...", error);
    });

    builder.addCase(revokePermissions.fulfilled, (state, { payload }) => {
      state.permissionsGranted = false;
    });
    builder.addCase(revokePermissions.rejected, (state, { error }) => {
      console.log("Couldn't revoke permissions...", error);
    });

    builder.addCase(setApps.fulfilled, (state, { payload }) => {
      state.filters.loading = false;
      state.filters.apps = payload;
    });
    builder.addCase(setApps.rejected, (state, { error }) => {
      console.log("Couldn't get apps list (datastreams)...", error);
    });
  },
});

export const { resetFilters, setFilters } = googleSlice.actions;

export default googleSlice.reducer;

function getInitialState() {
  return {
    filters: {
      date: {
        startDate: "2022-06-30",
        endDate: "2022-08-15",
      },
      apps: [
        {
          key: -1,
          text: "Loading",
          selected: true,
        },
      ],
      loading: true,
    },
    data: {
      // Device
      deviceCategory: {},
      deviceModel: {},
      platform: {},
      platformDeviceCategory: {},
      browser: {},
      language: {},
      operatingSystem: {},
      operatingSystemWithVersion: {},

      // User
      userAgeBracket: {},
      userGender: {},
      newVsReturning: {},
      city: {},
      country: {},
      region: {},

      // Sessions
      averageSessionLengthPerDay: {},
      sessionsPerDay: {},
    },
    status: {
      isLoading: true,
      isError: false,
    },
    permissionsGranted: "loading",
    permissionsRequestUrl: "",
  };
}

function collectSelectedFilters({ fromDate, toDate, captivePortal }) {
  let filters = {
    appId: 123456,
    dateRanges: {
      startDate: "yyyy-mm-dd",
      endDate: "yyyy-mm-dd",
    },
  };
  // get appId
  filters.appId = captivePortal;
  // get date
  filters.dateRanges.startDate = fromDate;
  filters.dateRanges.endDate = toDate;
  return filters;
}

async function getApps() {
  let { dataStreams } = await (
    await _fetchData(API_ENDPOINTS.apps, {}, "GET")
  ).json();

  let apps = dataStreams.map((app, i) => {
    return {
      key: app.name.split("/")[3],
      text: app.displayName,
      selected: false,
    };
  });

  return [
    {
      key: uuidv4(),
      text: "All",
      selected: true,
    },
    ...apps,
  ];
}
