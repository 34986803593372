import {
  START_MARKETPLACE_REQ,
  FINISH_MARKETPLACE_REQ,
  SET_MARKETPLACE_ERR,
  SET_MARKETPLACE_APPS,
  SET_MARKETPLACE_SERVICES,
  SET_INSTALLED_ITEMS,
  SET_MARKETPLACE_MODULES,
} from "../types";

const initialState = {
  loading: false,
  error: null,
  apps: null,
  services: null,
  modules: null,
  installed: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_MARKETPLACE_REQ:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FINISH_MARKETPLACE_REQ:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SET_MARKETPLACE_ERR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_MARKETPLACE_APPS:
      return {
        ...state,
        apps: action.payload,
      };
    case SET_MARKETPLACE_SERVICES:
      return {
        ...state,
        services: action.payload,
      };
    case SET_MARKETPLACE_MODULES:
      return {
        ...state,
        modules: action.payload,
      };
    case SET_INSTALLED_ITEMS:
      return {
        ...state,
        installed: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
