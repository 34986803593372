import {
  JP_SET_USER_INFO,
  JP_TOGGLE_SIDENAV,
  JP_SET_CATEGORIES,
  JP_SET_LOCATIONS,
  JP_SELECT_CATEGORY,
  JP_SELECT_SUB_CATEGORY,
  JP_SELECT_ITEM,
  JP_ADD_TO_CART,
  JP_DELETE_FROM_CART,
  JP_EMPTY_CART,
  JP_SET_POSROCKET_TOKEN,
  JP_SET_APP_COMPONENT,
  JP_SET_POS_ITEMS,
  JP_RESET_STATE,
  JP_FILTER_CATEGORIES,
} from "../types";

const initialState = {
  userInfo: {},
  currentComponent: "subcategories",
  access_token: null,
  error: null,
  isSidenav: false,
  filtered_categories: [],
  categories: [],
  items: [],
  locations: [],
  accessPoints: [],
  selected_category: null,
  selected_subCategory: null,
  subCategories: [],
  selected_item: null,
  orders: [],
  cartItems: [],
  taxes: [],
  tables: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case JP_SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case JP_SET_POSROCKET_TOKEN:
      return {
        ...state,
        access_token: action.payload,
      };
    case JP_TOGGLE_SIDENAV:
      return {
        ...state,
        isSidenav: !state.isSidenav,
      };
    case JP_SET_POS_ITEMS:
      return {
        ...state,
        items: action.payload,
        order_options: action.order_options ? action.order_options : null,
        taxes: action.taxes ? action.taxes : null,
        tables: action.tables ? action.tables : null,
      };
    case JP_SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload.filter((c) => c.parent_category === "0"),
        subCategories: action.payload.filter((c) => c.parent_category !== "0"),
      };
    case JP_FILTER_CATEGORIES:
      return {
        ...state,
        filtered_categories: action.cat_arr
          ? // filter using the array
            state.categories.map((c) => ({
              id: c.category_id,
              name: c.category_name,
              checked: action.cat_arr.includes(c.category_name) ? true : false,
            }))
          : // change object value
            state.filtered_categories.map((c) =>
              c.name === action.cat_name
                ? { ...c, checked: !c.checked }
                : { ...c }
            ),
      };
    case JP_SET_LOCATIONS:
      return {
        ...state,
        locations: action.locations,
        accessPoints: action.accessPoints,
      };
    case JP_SELECT_CATEGORY:
      return {
        ...state,
        selected_category: action.payload,
      };
    case JP_SELECT_SUB_CATEGORY:
      return {
        ...state,
        selected_subCategory: action.payload
          ? action.payload
          : state.selected_subCategory,
      };
    case JP_SELECT_ITEM:
      return {
        ...state,
        selected_item: action.payload,
      };
    case JP_ADD_TO_CART:
      return {
        ...state,
        orders: [action.payload.item, ...state.orders],
        cartItems: [...state.cartItems, action.payload.cart],
      };
    case JP_DELETE_FROM_CART:
      return {
        ...state,
        cartItems: state.cartItems.filter((item) => item.id !== action.payload),
      };
    case JP_EMPTY_CART:
      return {
        ...state,
        cartItems: [],
        orders: [],
      };
    case JP_SET_APP_COMPONENT:
      return {
        ...state,
        currentComponent: action.payload,
      };
    case JP_RESET_STATE:
      return {
        ...state,
        locations: [],
        accessPoints: [],
      };
    default:
      return state;
  }
};

export default reducer;
