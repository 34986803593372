import axios from "axios";
import {
  SET_USER_INFO,
  TOGGLE_SIDENAV,
  SET_CATEGORIES,
  SET_POS_ITEMS,
  SET_LOCATIONS,
  SELECT_CATEGORY,
  SELECT_ITEM,
  ADD_TO_CART,
  DELETE_FROM_CART,
  EMPTY_CART,
  SET_POSROCKET_TOKEN,
  SET_APP_COMPONENT,
  PR_RESET_STATE,
  PR_FILTER_CATEGORIES,
} from "../types";

export const setUser = (user) => {
  return { type: SET_USER_INFO, payload: user };
};

export const PR_reset_state = () => {
  return { type: PR_RESET_STATE };
};

export const setAppComponent = (current) => {
  return { type: SET_APP_COMPONENT, payload: current };
};

export const setToken = (token) => {
  return { type: SET_POSROCKET_TOKEN, payload: token };
};

export const toggleSidenav = () => {
  return { type: TOGGLE_SIDENAV };
};

export const selectCategory = (id) => {
  return { type: SELECT_CATEGORY, payload: id };
};

export const PR_filterCategories = (cat_arr = null, cat_name = null) => {
  return { type: PR_FILTER_CATEGORIES, cat_arr, cat_name };
};

export const selectItem = (id) => {
  return { type: SELECT_ITEM, payload: id };
};

export const addToCart = (item, name, price, quantity, rate) => {
  let cart = { id: item.id, name, price, quantity, rate };
  return { type: ADD_TO_CART, payload: { item, cart } };
};

export const deleteFromCart = (itemId) => {
  return { type: DELETE_FROM_CART, payload: itemId };
};

export const emptyCart = () => {
  return { type: EMPTY_CART };
};

export const update_locations_aps = (locations, accessPoints, cp_id) => {
  return {
    type: SET_LOCATIONS,
    locations,
    accessPoints: accessPoints.filter(
      (ap) => ap.cp_id === cp_id || ap.cp_id === null
    ),
  };
};

//fetch the data of categories and all (location based) items
export const getAllItems = (location) => {
  console.log(location);
  return async (dispatch, getState) => {
    try {
      let { categoriesFilter } = getState().cpEditor;
      let [categories, allItems] = await Promise.all([
        axios({
          method: "get",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pr_token")}`,
          },
          url: `${process.env.REACT_APP_API_URL}/posrocketapi/list-categories`,
        }),
        axios({
          method: "post",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pr_token")}`,
          },
          url: `${process.env.REACT_APP_API_URL}/posrocketapi/list-items`,
          data: { location_id: location },
        }),
      ]);
      dispatch({ type: SET_CATEGORIES, payload: categories.data.data });
      dispatch(PR_filterCategories(categoriesFilter, null));
      dispatch({ type: SELECT_CATEGORY, payload: categories.data.data[0].id });
      dispatch({ type: SET_POS_ITEMS, payload: allItems.data.data });
    } catch (e) {
      console.log(e);
    }
  };
};

// get locations and access points to connect them together
export const handleLocations = (cp_id) => {
  return async (dispatch) => {
    try {
      let [accessPoints, locations] = await Promise.all([
        axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/network/accesscontrollers/all`,
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }),
        axios({
          method: "get",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pr_token")}`,
          },
          url: `${process.env.REACT_APP_API_URL}/posrocketapi/get-locations`,
        }),
      ]);
      dispatch(getAllItems(locations.data.data[0].id));
      dispatch(
        update_locations_aps(locations.data.data, accessPoints.data, cp_id)
      );
      //dispatch({ type: SET_LOCATIONS, locations: locations.data.data, accessPoints: accessPoints.data })
    } catch (e) {
      console.log(e);
    }
  };
};
