import axios from "axios";
import {
  // START_MARKETPLACE_REQ,
  // FINISH_MARKETPLACE_REQ,
  // SET_MARKETPLACE_ERR,
  SET_MARKETPLACE_APPS,
  SET_MARKETPLACE_SERVICES,
  SET_INSTALLED_ITEMS,
  SET_MARKETPLACE_MODULES,
} from "../types";
// Actions
// function start() {
//   return { type: START_MARKETPLACE_REQ };
// }
// function finish() {
//   return { type: FINISH_MARKETPLACE_REQ };
// }
// function setErr(payload) {
//   return { type: SET_MARKETPLACE_ERR, payload };
// }
function setApps(payload) {
  return { type: SET_MARKETPLACE_APPS, payload };
}
function setServices(payload) {
  return { type: SET_MARKETPLACE_SERVICES, payload };
}
function setModules(payload) {
  return { type: SET_MARKETPLACE_MODULES, payload };
}
function setInstalled(payload) {
  return { type: SET_INSTALLED_ITEMS, payload };
}
export const getApps = () => {
  return (dispatch) => {
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/marketplace/apps`,
    })
      .then((result) => {
        dispatch(setApps(result.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getInstalled = () => {
  return (dispatch) => {
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/marketplace/installed`,
    })
      .then((result) => {
        dispatch(setInstalled(result.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getServices = () => {
  return (dispatch) => {
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/marketplace/services`,
    })
      .then((result) => {
        dispatch(setServices(result.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getAnalyticalModules = () => {
  return (dispatch) => {
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      url: `${process.env.REACT_APP_API_URL}/marketplace/modules`,
    })
      .then((result) => {
        console.log(result.data.data);
        dispatch(setModules(result.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
