import React from "react";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
  return (
    <>
      <nav style={{ padding: "20px" }}>
        <Link to="/">
          <a className="navbar-brand" href="#!">
            <img
              src="../../assets/images/basic.png"
              width="60"
              height="60"
              alt=""
            />
          </a>
        </Link>
      </nav>
      <div
        style={{ padding: "20px" }}
        dangerouslySetInnerHTML={{
          __html: `
        <p align="center" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif"><u><b>Facebook
        App Privacy Policy</b></u></font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <br/>
        <br/>

        </p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif">This
        privacy statement (“Privacy Statement”) applies to the treatment
        of personally identifiable information submitted by, or otherwise
        obtained from, you in connection with the associated application
        (“Application”). The Application is provided by HOOPOE DIGITAL
        LLC (and may be provided by HOOPOE DIGITAL LLC on behalf of a HOOPOE
        DIGITAL LLC licensor or partner (“Application Partner”). By using
        or otherwise accessing the Application, you acknowledge that you
        accept the practices and policies outlined in this Privacy Statement.</font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <br/>
        <br/>

        </p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif"><b>WHAT
        PERSONAL INFORMATION DOES HOOPOE DIGITAL LLC COLLECT?</b></font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif">We
        collect the following types of information from our users:</font></font></font></p>
        <ol>
          <li><p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
          <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif"><b>Personal
          Information You Provide to Us: </b></font></font></font>
          </p>
        </ol>
        <p align="justify" style="margin-left: 0.5in; margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif">We
        may receive and store any information you submit to the Application
        (or otherwise authorize us to obtain – such as, from (for example)
        your Facebook account). The types of personal information collected
        may include your full name, email address, gender, IP address,
        browser information, username, demographic information, and any other
        information necessary for us to provide the Application services.</font></font></font></p>
        <ol start="2">
          <li><p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
          <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif"><b>Personal
          Information Collected Automatically:</b></font></font></font></p>
        </ol>
        <p align="justify" style="margin-left: 0.5in; margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif">We
        receive and store certain types of usage related information whenever
        you interact with Application. For example, HOOPOE DIGITAL LLC may
        automatically receive and record information regarding your
        computer’s IP address, browser information, Facebook user ID,
        Facebook Page fan status, and URLs accessed. Such information may be
        shared in aggregate (non-personally identifiable) form with our
        partners.</font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif"><b>HOW
        DOES HOOPOE DIGITAL LLC USE THE INFORMATION IT COLLECTS?</b></font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif">HOOPOE
        DIGITAL LLC uses the information described in this Privacy Statement
        (i) internally, to analyze, develop and improve its products and
        services, and (ii) as set forth below in the “Will HOOPOE DIGITAL
        LLC Share any of the personal information it Collects” section
        below.</font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif"><b>APPLICATION
        PARTNER TREATMENT OF PERSONAL INFORMATION.</b></font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif">HOOPOE
        DIGITAL LLC may provide personal information to the applicable
        Application Partner. The Application Partner’s use of your personal
        information is subject to the Application Partner’s separate
        privacy policy – and not this Privacy Statement. The Application
        Partner’s privacy policy is linked to from within the Partner’s
        Facebook application.</font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif"><b>WILL
        HOOPOE DIGITAL LLC SHARE ANY OF THE PERSONAL INFORMATION IT RECEIVES?</b></font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif">Personal
        information about our users is an integral part of our business. We
        neither rent nor sell your personal information to anyone (with the
        exception of sharing your information with an applicable Application
        Partner – see the “Application Partner Treatment” section
        above). We share your personal information only as described below.</font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif"><b>Application
        Partners:</b></font></font><font color="#6e6e6e"><font face="Arial, serif">
        We will share your personal information with an applicable
        Application Partner (see the “Application Partner Treatment”
        section above).</font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif"><b>Agents:</b></font></font><font color="#6e6e6e"><font face="Arial, serif">
        We employ other companies and people to perform tasks on our behalf
        and need to share your information with them to provide products or
        services to you. Unless we tell you differently, HOOPOE DIGITAL LLC’s
        agents do not have any right to use personal information we share
        with them beyond what is necessary to assist us. You hereby consent
        to our sharing of personal information for the above purposes.
        Business Transfers: In some cases, we may choose to buy or sell
        assets. In these types of transactions, customer information is
        typically one of the business assets that are transferred. Moreover,
        if HOOPOE DIGITAL LLC, or substantially all of its assets were
        acquired, or in the unlikely event that HOOPOE DIGITAL LLC goes out
        of business or enters bankruptcy, user information would be one of
        the assets that is transferred or acquired by a third party. You
        acknowledge that such transfers may occur, and that any acquirer of
        HOOPOE DIGITAL LLC may continue to use your personal information as
        set forth in this policy.</font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif">Protection
        of HOOPOE DIGITAL LLC and Others: We may release personal information
        when we believe in good faith that release is necessary to comply
        with the law; enforce or apply our conditions of use and other
        agreements; or protect the rights, property, or safety of HOOPOE
        DIGITAL LLC, our employees, our users, or others. This includes
        exchanging information with other companies and organizations for
        fraud protection and credit risk reduction.</font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif">With
        Your Consent: Except as set forth above, you will be notified when
        your personal information may be shared with third parties and will
        be able to prevent the sharing of this information.</font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif"><b>CONDITIONS
        OF USE.</b></font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif">If
        you decide to use or otherwise access the Application, your
        use/access and any possible dispute over privacy is subject to this
        Privacy Statement and our Terms of Use, including limitations on
        damages, arbitration of disputes, and application of California state
        law.</font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif"><b>THIRD
        PARTY APPLICATIONS/WEBSITES.</b></font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif">The
        Application may permit you to link to other applications or websites.
        Such third-party applications/websites are not under HOOPOE DIGITAL
        LLC’s control, and such links do not constitute an endorsement by
        HOOPOE DIGITAL LLC of those other applications/websites, or the
        services offered through them. The privacy and security practices of
        such third-party application/websites linked to the Application are
        not covered by this Privacy Statement, and HOOPOE DIGITAL LLC is not
        responsible for the privacy or security practices or the content of
        such websites.</font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif"><b>WHAT
        PERSONAL INFORMATION CAN I ACCESS?</b></font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif">HOOPOE
        DIGITAL LLC allows you to access the following information about you
        for the purpose of viewing, and in certain situations, updating that
        information. This list may change in the event the Application
        changes.</font></font></font></p>
        <ul>
          <li><p align="justify" style="margin-bottom: 0in; line-height: 100%">
          <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif">Account
          and user profile information.</font></font></font></p>
          <li><p align="justify" style="margin-bottom: 0in; line-height: 100%">
          <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif">User
          e-mail address, if applicable.</font></font></font></p>
          <li><p align="justify" style="margin-bottom: 0in; line-height: 100%">
          <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif">Facebook
          profile information, if applicable.</font></font></font></p>
          <li><p align="justify" style="margin-bottom: 0in; line-height: 100%">
          <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif">User
          preferences.</font></font></font></p>
          <li><p align="justify" style="margin-bottom: 0.16in; line-height: 100%">
          <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif">Application
          specific data.</font></font></font></p>
        </ul>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif"><b>CAN
        CHILDREN USE THE APPLICATION?</b></font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif">Our
        site and the services available through HOOPOE DIGITAL LLC are not
        intended for children under the age of 13. HOOPOE DIGITAL LLC does
        not knowingly or specifically collect information about children
        under the age of 13 and believes that children of any age should get
        their parents’ consent before giving out any personal information.
        We encourage you to participate in your child’s web experience.</font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif"><b>CHANGES
        TO THIS PRIVACY STATEMENT.</b></font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif">HOOPOE
        DIGITAL LLC may amend this Privacy Statement from time to time. Use
        of information we collect now is subject to the Privacy Statement in
        effect at the time such information is used. If we make changes in
        the way we use personal information, we will notify you by posting an
        announcement on our Site or sending you an email. Users are bound by
        any changes to the Privacy Statement when he or she uses or otherwise
        accesses the Application after such changes have been first posted.</font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <br/>
        <br/>

        </p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <br/>
        <br/>

        </p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <br/>
        <br/>

        </p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif"><b>QUESTIONS
        OR CONCERNS.</b></font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in">
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif">If
        you have any questions or concerns regarding privacy on our Website,
        please send us a detailed message (</font></font><a href="mailto:info@hoopoe.digital"><font face="Arial, serif">info@hoopoe.digital</font></a><font color="#6e6e6e"><font face="Arial, serif">)
        . We will make every effort to resolve your concerns.</font></font></font></p>
        <p align="justify" style="margin-bottom: 0.16in; line-height: 0.29in"><a name="_GoBack"></a>
        <font face="Times New Roman, serif"><font color="#6e6e6e"><font face="Arial, serif">Effective
        Date: January 1, 2020</font></font></font></p>
        <p align="justify" style="margin-bottom: 0in; line-height: 100%"><br/></p>
    `,
        }}
      />
    </>
  );
}
