import {
  POS_SET_FILTER_MODE,
  POS_RESET_STATE,
  POS_SET_TOKEN,
  POS_SET_LOCATIONS,
  SAVE_FILTERED_DATA,
  UPDATE_FILTERED_CATEGORIES,
  UPDATE_FILTERED_ITEMS,
  SET_BOT_CONFIG
} from "../types";

const initialState = {
  filter_mode: false,
  access_token: null,
  filteredCategories: [],
  filteredItems: [],
  locations: [],
  whatsAppBotEnabling: false,
  botConfig: {
    name: { ar: "", en: "" },
    tags: {
      ar: [],
      en: []
    },
    geoLocation: { lat: "30.01842113", lng: "31.42256780" },
    country: "Egypt",
    city: "",
    orderOptionId: "",
    images: {
      Main: {
        ar: "",
        en: "",
      },
      Sides: null,
    }
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POS_SET_TOKEN:
      return { ...state, access_token: action.payload };

    case POS_SET_FILTER_MODE:
      return { ...state, filter_mode: !state.filter_mode };

    case POS_SET_LOCATIONS:
      return {
        ...state,
        locations: action.locations,
        accessPoints: action.accessPoints,
      };

    case SAVE_FILTERED_DATA:
      return {
        ...state,
        filteredCategories: action.payload.categories,
        filteredItems: action.payload.items,
      };

    case SET_BOT_CONFIG:
      return {
        ...state,
        whatsAppBotEnabling: action.payload.whatsAppBotEnabling ? action.payload.whatsAppBotEnabling : false,
        botConfig: action.payload.botConfig ? action.payload.botConfig : state.botConfig,
      };


    case UPDATE_FILTERED_CATEGORIES:
      console.log("UPDATE_FILTERED_CATEGORIES");
      let cat_set = state.filteredCategories;
      let cat_index = cat_set.length ? cat_set.indexOf(action.id) : -1;
      cat_index === -1 ? cat_set.push(action.id) : cat_set.splice(cat_index, 1);
      return {
        ...state,
        filteredCategories: [...cat_set],
      };

    case UPDATE_FILTERED_ITEMS:
      console.log("UPDATE_FILTERED_ITEMS");
      let item_set = state.filteredItems;
      let item_index = item_set.length ? item_set.indexOf(action.id) : -1;
      item_index === -1
        ? item_set.push(action.id)
        : item_set.splice(item_index, 1);
      return {
        ...state,
        filteredItems: [...item_set],
      };

    case POS_RESET_STATE:
      return { ...state, locations: [], accessPoints: [] };

    default:
      return state;
  }
};

export default reducer;
