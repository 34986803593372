// template
export const COLLAPSE_MENU = "COLLAPSE_MENU";
export const COLLAPSE_TOGGLE = "COLLAPSE_TOGGLE";
export const FULL_SCREEN = "FULL_SCREEN";
export const FULL_SCREEN_EXIT = "FULL_SCREEN_EXIT";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const NAV_CONTENT_LEAVE = "NAV_CONTENT_LEAVE";
export const NAV_COLLAPSE_LEAVE = "NAV_COLLAPSE_LEAVE";
export const SET_ACTIVE_COMPONENT = "SET_ACTIVE_COMPONENT";
export const USER_EDITING = "USER_EDITING";
// auth
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";
export const SET_STEP = "SET_STEP";
export const UNSET_ERROR = "UNSET_ERROR";
export const INCOMPLETE_PROFILE = "INCOMPLETE_PROFILE";
// profile
export const START_STEP = "START_STEP";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const FAIL_STEP = "FAIL_STEP";
export const SEND_MSG = "SEND_MSG";
export const SUCCESS_STEP = "SUCCESS_STEP";
export const CLEAR_ERROR = "CLEAR_ERROR";
// Cloud
export const CLOUD_LOADING = "CLOUD_LOADING";
export const CLOUD_ERROR = "CLOUD_ERROR";
export const ADD_CP = "ADD_CP";
export const GET_OFFERS = "GET_OFFERS";
export const SET_EXTRA_PACKAGES = "SET_EXTRA_PACKAGES";
export const GET_SSIDS = "GET_SSIDS";
export const GET_APGROUPS = "GET_APGROUPS";
export const GET_APS = "GET_APS";
export const SET_COMPONENT = "SET_COMPONENT";
export const SET_GATEWAY_COMPONENT = "SET_GATEWAY_COMPONENT";
export const SET_GATEWAY_DATA = "SET_GATEWAY_DATA";
export const CHANGE_GATEWAY_FORM = "CHANGE_GATEWAY_FORM";
export const START_GATEWAY_REQ = "START_GATEWAY_REQ";
export const END_GATEWAY_REQ = "END_GATEWAY_REQ";
export const SET_SELECTED_GATEWAY = "SET_SELECTED_GATEWAY";
export const SET_GATEWAY_FORM = "SET_GATEWAY_FORM";
// cpEditor
export const SET_NAME = "SET_NAME";
export const CHANGE_LOGO = "CHANGE_LOGO";
export const CHANGE_DM_LOGO = "CHANGE_DM_LOGO";
export const CHANGE_DM_LOGINBG = "CHANGE_DM_LOGINBG";
export const CHANGE_LOGIN_BG = "CHANGE_LOGIN_BG";
export const CHANGE_TITLE = "CHANGE_TITLE";
export const CHANGE_THEME = "CHANGE_THEME";
export const CHANGE_DESC = "CHANGE_DESC";
export const CHANGE_POWEREDBY = "CHANGE_POWEREDBY";
export const CHANGE_SIGNUP = "CHANGE_SIGNUP";
export const CHANGE_FORMDETAILS = "CHANGE_FORMDETAILS";
export const ACTIVE_TAB = "ACTIVE_TAB";
export const CHANGE_TAB = "CHANGE_TAB";
export const SAVE_PAGE = "SAVE_PAGE";
export const ASSIGN_SSIDS = "ASSIGN_SSIDS";
export const ASSIGN_VOUCHERS = "ASSIGN_VOUCHERS";
export const RESET_FORMDETAILS = "RESET_FORMDETAILS";
export const RESET_STATE = "RESET_STATE";
export const SET_OFFER = "SET_OFFER";
export const SET_FALL_BACK = "SET_FALL_BACK";
export const SET_DISABLE_ADS = "SET_DISABLE_ADS";
export const SET_ID = "SET_ID";
export const SET_LOGO = "SET_LOGO";
export const CHANGE_BG = "CHANGE_BG";
export const SET_BG = "SET_BG";
export const CHANGE_SIGNUP_METHODS = "CHANGE_SIGNUP_METHODS";
export const RESET_SIGNUP_METHODS = "RESET_SIGNUP_METHODS";
export const SET_TOKEN = "SET_TOKEN";
export const TABS_FOR_EDIT = "TABS_FOR_EDIT";
export const SET_LOCATIONS_POINTS = "SET_LOCATIONS_POINTS";
export const CHANGE_SIGNUP_METHOD_OFFER = "CHANGE_SIGNUP_METHOD_OFFER";
export const SET_MENU_FILTER = "SET_MENU_FILTER";
export const SET_HOST_MAC = "SET_HOST_MAC";
export const SET_DEFAULT_LANGUAGE = "SET_DEFAULT_LANGUAGE";
export const SET_SPOTIFY_TOKEN = "SET_SPOTIFY_TOKEN";
export const TOGGLE_NAME_VALUE = "TOGGLE_NAME_VALUE";
export const CHANGE_BYPASS_LANDING = "CHANGE_BYPASS_LANDING";
//analyticsConfig
export const ANALYTICS_START = "ANALYTICS_START";
export const ANALYTICS_ERROR = "ANALYTICS_ERROR";
export const ANALYTICS_SUCCESS = "ANALYTICS_SUCCESS";
export const SET_MODULES = "SET_MODULES";
export const SET_CHARTS = "SET_CHARTS";
export const SET_INSTALLED_MODULES = "SET_INSTALLED_MODULES";
export const SET_INSTALLED_CHARTS = "SET_INSTALLED_CHARTS";
export const SET_MODULE = "SET_MODULE";
export const ADD_MODULE = "ADD_MODULE";
export const REMOVE_MODULE = "REMOVE_MODULE";
//analytics
export const ANALYTIC_START = "ANALYTIC_START";
export const ANALYTIC_MSG = "ANALYTIC_MSG";
export const GET_MODULES = "GET_MODULES";
export const GET_CHARTS = "GET_CHARTS";
export const SET_FILTER = "SET_FILTER";
export const SET_GROUPS = "SET_GROUPS";
export const SET_APS = "SET_APS";
export const SET_ANALYTICS_APP_DATA = "SET_ANALYTICS_APP_DATA";
//vouchers
export const SET_VOUCHERS = "SET_VOUCHERS";
//PosRocketApp
export const SET_USER_INFO = "SET_USER_INFO";
export const TOGGLE_SIDENAV = "TOGGLE_SIDENAV";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_LOCATIONS = "SET_LOCATIONS";
export const SELECT_CATEGORY = "SELECT_CATEGORY";
export const SELECT_ITEM = "SELECT_ITEM";
export const ADD_TO_CART = "ADD_TO_CART";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const EMPTY_CART = "EMPTY_CART";
export const SET_POSROCKET_TOKEN = "SET_POSROCKET_TOKEN";
export const SET_APP_COMPONENT = "SET_APP_COMPONENT";
export const SET_POS_ITEMS = "SET_POS_ITEMS";
export const PR_RESET_STATE = "PR_RESET_STATE";
export const PR_FILTER_CATEGORIES = "PR_FILTER_CATEGORIES";
//JoinPosterApp
export const JP_SET_USER_INFO = "JP_SET_USER_INFO";
export const JP_TOGGLE_SIDENAV = "JP_TOGGLE_SIDENAV";
export const JP_SET_CATEGORIES = "JP_SET_CATEGORIES";
export const JP_SET_LOCATIONS = "JP_SET_LOCATIONS";
export const JP_SELECT_CATEGORY = "JP_SELECT_CATEGORY";
export const JP_SELECT_SUB_CATEGORY = "JP_SELECT_SUB_CATEGORY";
export const JP_SELECT_ITEM = "JP_SELECT_ITEM";
export const JP_ADD_TO_CART = "JP_ADD_TO_CART";
export const JP_DELETE_FROM_CART = "JP_DELETE_FROM_CART";
export const JP_EMPTY_CART = "JP_EMPTY_CART";
export const JP_SET_POSROCKET_TOKEN = "JP_SET_POSROCKET_TOKEN";
export const JP_SET_APP_COMPONENT = "JP_SET_APP_COMPONENT";
export const JP_SET_POS_ITEMS = "JP_SET_POS_ITEMS";
export const JP_RESET_STATE = "JP_RESET_STATE";
export const JP_FILTER_CATEGORIES = "JP_FILTER_CATEGORIES";
//pos
export const POS_SET_USER_INFO = "POS_SET_USER_INFO";
export const POS_SET_TOKEN = "POS_SET_TOKEN";
export const POS_TOGGLE_SIDENAV = "POS_TOGGLE_SIDENAV";
export const POS_SET_LOCATIONS = "POS_SET_LOCATIONS";
export const POS_SET_ITEMS = "POS_SET_ITEMS";
export const POS_SET_PR_CATEGORIES = "POS_SET_PR_CATEGORIES";
export const POS_SET_JP_CATEGORIES = "POS_SET_JP_CATEGORIES";
export const POS_SELECT_CATEGORY = "POS_SELECT_CATEGORY";
export const POS_SELECT_SUB_CATEGORY = "POS_SELECT_SUB_CATEGORY";
export const POS_FILTER_CATEGORIES = "POS_FILTER_CATEGORIES";
export const POS_FILTER_JP_CATEGORIES = "POS_FILTER_JP_CATEGORIES";
export const POS_SELECT_ITEM = "POS_SELECT_ITEM";
export const POS_ADD_TO_CART = "POS_ADD_TO_CART";
export const POS_DELETE_FROM_CART = "POS_DELETE_FROM_CART";
export const POS_EMPTY_CART = "POS_EMPTY_CART";
export const POS_SET_APP_COMPONENT = "POS_SET_APP_COMPONENT";
export const POS_RESET_STATE = "POS_RESET_STATE";
export const POS_SET_FILTER_MODE = "POS_SET_FILTER_MODE";
export const POS_FILTER_ITEMS = "POS_FILTER_ITEMS";
export const POS_SET_HP_CATEGORIES = "POS_SET_HP_CATEGORIES";
export const SAVE_FILTERED_DATA = "SAVE_FILTERED_DATA";
export const UPDATE_FILTERED_CATEGORIES = "UPDATE_FILTERED_CATEGORIES";
export const UPDATE_FILTERED_ITEMS = "UPDATE_FILTERED_ITEMS";
export const SET_BOT_CONFIG = "SET_BOT_CONFIG";
//Dashboard
export const DASHBOARD_START = "DASHBOARD_START";
export const DASHBOARD_MSG = "DASHBOARD_MSG";
export const GET_REGISTERED = "GET_REGISTERED";
export const GET_ONLINE = "GET_ONLINE";
export const SET_ONLINE_DEVICES = "SET_ONLINE_DEVICES";
export const GET_ACCESSPOINTS = "GET_ACCESSPOINTS";
export const SET_GENERATED_SESSIONS = "SET_GENERATED_SESSIONS";
export const GET_DOWNLOAD = "GET_DOWNLOAD";
export const GET_UPLOAD = "GET_UPLOAD";
export const SHOW_ONLINE = "SHOW_ONLINE";
export const SHOW_REGISTERED = "SHOW_REGISTERED";
export const SHOW_LOCATIONS = "SHOW_LOCATIONS";
export const SHOW_DEVICE_INFO = "SHOW_DEVICE_INFO";
export const SHOW_ONLINE_DEVICE_INFO = "SHOW_ONLINE_DEVICE_INFO";
export const SHOW_AP_STATUS = "SHOW_AP_STATUS";
export const SET_REGISTERED_DEVICES = "SET_REGISTERED_DEVICES";
export const SET_AVG_SESSION_DURATION = "SET_AVG_SESSION_DURATION";
export const SET_AVG_GB_PER_DAY = "SET_AVG_GB_PER_DAY";
export const SET_SMS_CONSUMPTION = "SET_SMS_CONSUMPTION";

//Network Configuration
export const NETWORK_LOADING = "NETWORK_LOADING";
export const NETWORK_ERROR = "NETWORK_ERROR";
export const ADD_AP = "ADD_AP";
export const ADD_SSID = "ADD_SSID";
export const ADD_APGROUP = "ADD_APGROUP";
export const GET_MODELS = "GET_MODELS";
export const GET_SECRET = "GET_SECRET";
export const CHANGE_APFORM = "CHANGE_APFORM";
export const TOGGLE_MAP = "TOGGLE_MAP";
export const SET_COORDINATES = "SET_COORDINATES";
export const CHANGE_SSIDFORM = "CHANGE_SSIDFORM";
export const CHANGE_APGROUPFORM = "CHANGE_APGROUPFORM";
export const SET_APSCOMPONENT = "SET_APCOMPONENT";
export const SET_SSIDSCOMPONENT = "SET_SSIDSCOMPONENT";
export const SET_GROUPSCOMPONENT = "SET_GROUPSCOMPONENT";
export const GET_SCREENS = "GET_SCREENS";
export const ADD_SCREEN = "ADD_SCREEN";
export const SET_SCREENSCOMPONENT = "SET_SCREENSCOMPONENT";
export const CHANGE_SCREENSFORM = "CHANGE_SCREENSFORM";

//QS
export const SET_QS_QUEUE = "SET_QS_QUEUE";
export const SET_QS_SERVICES = "SET_QS_SERVICES";
export const SET_QS_BRANCHES = "SET_QS_BRANCHES";
export const REMOVE_QS_BRANCH = "REMOVE_QS_BRANCH";
export const SET_QS_COUNTERS = "SET_QS_COUNTERS";
export const SET_DB_COUNTER = "SET_DB_COUNTER";
export const SET_DB_BRANCHES = "SET_DB_BRANCHES";
export const SET_DB_BRANCH = "SET_DB_BRANCH";
export const SET_DB_UPDATETIME = "SET_DB_UPDATETIME";
export const TOGGLE_NEW_ORDER = "TOGGLE_NEW_ORDER";
export const SET_MODAL_ITEM = "SET_MODAL_ITEM";
export const SET_ORDERS_DATA = "SET_ORDERS_DATA";
export const SET_RESTAURANT_NAME = "SET_RESTAURANT_NAME";
export const SET_NEW_ORDERS = "SET_NEW_ORDERS";
export const MAKE_ORDERS_SEEN = "MAKE_ORDERS_SEEN";
export const SET_LOGS_CP = "SET_LOGS_CP";
export const SAVE_LOGS = "SAVE_LOGS";
export const CHANGE_LOGS_FILTER = "CHANGE_LOGS_FILTER";
export const TOGGLE_TICKET_MODAL = "TOGGLE_TICKET_MODAL";
export const RESET_QS_STATE = "RESET_QS_STATE";
export const SET_QS_BRANCHFORM = "SET_QS_BRANCHFORM";
export const EDIT_QS_BRANCH = "EDIT_QS_BRANCH";
export const DOARAK_SET_APP = "DOARAK_SET_APP";
export const TRIGGER_NOTIFICATION = "TRIGGER_NOTIFICATION";

//WiFi Authentication

export const CHANGE_WIFI_AUTH_COMP = "CHANGE_WIFI_AUTH_COMP";

//Marketplace
export const START_MARKETPLACE_REQ = "START_MARKETPLACE_REQ";
export const FINISH_MARKETPLACE_REQ = "FINISH_MARKETPLACE_REQ";
export const SET_MARKETPLACE_ERR = "SET_MARKETPLACE_ERR";
export const SET_MARKETPLACE_APPS = "SET_MARKETPLACE_APPS";
export const SET_MARKETPLACE_SERVICES = "SET_MARKETPLACE_SERVICES";
export const SET_MARKETPLACE_MODULES = "SET_MARKETPLACE_MODULES";
export const SET_INSTALLED_ITEMS = "SET_INSTALLED_ITEMS";
//DOCS
export const DOCS_SET_MODULES = "DOCS_SET_MODULES";
