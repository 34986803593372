import {
  CLOUD_LOADING,
  CLOUD_ERROR,
  ADD_CP,
  GET_OFFERS,
  GET_SSIDS,
  SET_COMPONENT,
  GET_APGROUPS,
  GET_APS,
  SET_GATEWAY_COMPONENT,
  CHANGE_GATEWAY_FORM,
  START_GATEWAY_REQ,
  END_GATEWAY_REQ,
  SET_GATEWAY_DATA,
  SET_GATEWAY_FORM,
  SET_EXTRA_PACKAGES,
} from "../types";

const initialState = {
  isLoading: false,
  error: null,
  captivePortals: [],
  offers: [],
  extraPackages: [],
  ssids: [],
  apgroups: [],
  aps: [],
  currentComponent: "overview",
  gatewayComponent: "overview",
  gatewayForm: {},
  smsGateways: [],
  gatewayLoading: false,
  gatewayError: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLOUD_LOADING:
      return {
        ...state,
        isLoading: action.payload,
        error: action.payload && null,
      };
    case CLOUD_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case ADD_CP:
      return {
        ...state,
        captivePortals: action.payload,
      };
    case GET_OFFERS:
      return {
        ...state,
        offers: action.payload,
      };
    case SET_EXTRA_PACKAGES:
      return {
        ...state,
        extraPackages: action.payload,
      };
    case GET_SSIDS:
      return {
        ...state,
        ssids: action.payload,
      };
    case GET_APGROUPS:
      return {
        ...state,
        apgroups: action.payload,
      };
    case GET_APS:
      return {
        ...state,
        aps: action.payload,
      };
    case SET_COMPONENT:
      return {
        ...state,
        currentComponent: action.payload,
      };
    case SET_GATEWAY_COMPONENT:
      return {
        ...state,
        gatewayComponent: action.payload,
        gatewayForm:
          action.payload === "overview"
            ? {}
            : action.payload === "new"
            ? {}
            : action.gatewayForm,
      };
    case CHANGE_GATEWAY_FORM:
      return {
        ...state,
        gatewayError: null,
        gatewayForm: { ...state.gatewayForm, [action.property]: action.value },
      };
    case SET_GATEWAY_FORM:
      return {
        ...state,
        gatewayForm: action.payload,
      };
    case START_GATEWAY_REQ:
      return {
        ...state,
        gatewayLoading: true,
      };
    case END_GATEWAY_REQ:
      return {
        ...state,
        gatewayLoading: false,
        gatewayError: action.error,
      };
    case SET_GATEWAY_DATA:
      return {
        ...state,
        smsGateways: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
